import {
  RenderTexture,
  Html,
  useGLTF,
  MeshDistortMaterial,
 //  useTexture,
  MeshTransmissionMaterial,
  useAnimations,
  Environment,
  useKTX2,useFBO,
  Float, Bvh, Detailed
} from "@react-three/drei";

import {
  EffectComposer,
  Noise,Sepia,Scanline
} from "@react-three/postprocessing";


useGLTF.setDecoderPath("../libs/draco/");
//useKTX2.basisPath="../libs/basis/";
import * as THREE from "three";


import { useRef, useEffect, useState } from "react";
import { Physics, RigidBody, CuboidCollider } from "@react-three/rapier";

import { useFrame } from "@react-three/fiber";
import useSound from "use-sound";

import { useStore } from "../components/GameStore.jsx";
import PortalScene from "../scenes/PortalScene.jsx";
import CameraControlsBox from "../components/CameraControlsBox03.jsx";
import Postprocessing from "../components/Postprocessing03.jsx";

import { usePageVisibility } from "../components/Visibility.jsx";
import Toast from "../components/Toast.jsx";
import background from "/sound/background.mp3";

import blurp from "/sound/blurp.wav";
import dingdong from "/sound/dingdong.mp3";
import gurgle from "/sound/gurgle.wav";

export default function RoomScene(props) {
  const group = useRef();
 const screen = useRef();
 const controlcamera = useRef();
  const webmail = useStore((state) => state.webmail);
  const shop = useStore((state) => state.shop);
  const p1 = useStore((state) => state.p1);
  const p2 = useStore((state) => state.p2);
  const p3 = useStore((state) => state.p3);
  const p4 = useStore((state) => state.p4);

  const isVisible = usePageVisibility();

  const buffer = useFBO()
  const buffer2 = useFBO(700,500)
  const buffer3 = useFBO(700,500)

  const effects = useRef();

  const glass1 = useRef();
  const glass2 = useRef();

  const [playGurgle] = useSound(gurgle);
  const [playDingdong] = useSound(dingdong);
  const [playBlurp] = useSound(blurp);
  const [playBackground, { stop, pause }] = useSound(background, {
    loop: true,
    volume: 1,
    interrupt: true,
  });

  playBackground();
  const startTrip = () => {
    useStore.setState({ scene: "TripScene" });
  };
  const modalRef = useRef();
  const frame = useRef();
  const camera = useRef();
  const cup = useRef();
  const monster = useRef();
  const v1 = useRef();
  const fluffy = useRef();
 const coso = useRef();
 // const hundi = useRef();
  const freccia = useRef();

  const ball1 = useRef();
  const ball2 = useRef();
  const ball3 = useRef();
  const ball4 = useRef();
  const ball5 = useRef();
  const ball6 = useRef();
  const ball7 = useRef();

const meshRef= useRef()


  const z1 = useRef();
  const z2 = useRef();
  const z3 = useRef();

  const { nodes, materials, animations } = useGLTF("../models/RoomScene.glb");
  const roomModelMaterial= useGLTF("../models/RoomSceneMaterial.glb");
  //const roomModelMaterial   = useGLTF("../models/RoomSceneMaterial.glb");
 const { actions, mixer } = useAnimations( animations, group);
const an2 = useAnimations( roomModelMaterial.animations,group);
   //const { actions, mixer } = useAnimations( roomModelMaterial.animations.concat(animations), group);

  //const { actions, mixer } = useAnimations( [...roomModelMaterial.animations, ...animations], group);


// console.log( nodes)
// console.log( scene)
// console.log(actions2)
 //console.log(roomModelMaterial.animations.push(...animations))
  const [ani, setAni] = useState(1);

  const [box, setBox] = useState(false);

  mixer.addEventListener("finished", function (e) {
    if (e.action._clip.name == "walking2" && !actions?.walking4.isRunning()) {
    
      actions?.walking4
        .reset()
        .crossFadeFrom(actions?.walking1, 0.5, true)
        .play();

      setAni(3);
    }
    if (e.action._clip.name == "end1") {
     
      startTrip();
    }
    if (e.action._clip.name == "end3") {
   
    
    

      setTimeout(() => {
        v1.current.visible =true;
    
      }, "1200");

      playBlurp();
      stop();

    




  
    }
    if (e.action._clip.name == "vomit1") {
   
  
     
      z1.current.visible = false;
   
     }    if (e.action._clip.name == "vomit2") {
   
  
     
      z2.current.visible = false;
   
     }  if (e.action._clip.name == "vomit3") {
   
  
     
      z3.current.visible = false;
   
     }
  });

  useEffect(() => {
   // effects.current.enable=false
 effects.current.autoRenderToScreen=false
   // effects.current.inputBuffer = buffer
   effects.current.outputBuffer = buffer3

  //console.log(  meshRef.current)
    freccia.current.setEnabled(false)

  /*   ball1.current.setEnabled(false)
    ball2.current.setEnabled(false)
    ball3.current.setEnabled(false)
    ball4.current.setEnabled(false)
    ball5.current.setEnabled(false)
    ball6.current.setEnabled(false)
    ball7.current.setEnabled(false) */

    actions.hundiAction.setLoop(THREE.LoopOnce, 1);



    actions.walking2.setLoop(THREE.LoopOnce, 1);
    actions.walking3.setLoop(THREE.LoopOnce, 1);
    actions.open1.setLoop(THREE.LoopOnce, 1);

    actions.end1.setLoop(THREE.LoopOnce, 1);
    actions.end2.setLoop(THREE.LoopOnce, 1);
    actions.end3.setLoop(THREE.LoopOnce, 1);

 actions.vomit1.setLoop(THREE.LoopOnce, 1);
    actions.vomit2.setLoop(THREE.LoopOnce, 1);
    actions.vomit3.setLoop(THREE.LoopOnce, 1);

    actions.toy.setLoop(THREE.LoopOnce, 1);
    actions.opentoy.setLoop(THREE.LoopOnce, 1);


      actions?.corpoAction.setLoop(THREE.LoopOnce, 1);
       actions?.aliAction.setLoop(THREE.LoopOnce, 1);
      
  

       actions.hundiAction.paused=true;


    actions.toy.paused=true;
    actions.opentoy.paused=true;

    actions.toy.clampWhenFinished = true;
    actions.opentoy.clampWhenFinished = true;


    actions.hundiAction.clampWhenFinished = true;
    actions.walking2.clampWhenFinished = true;
    actions.walking3.clampWhenFinished = true;
   actions.open1.clampWhenFinished = true;

   actions.end1.clampWhenFinished = true;
    actions.end2.clampWhenFinished = true;
    actions.end3.clampWhenFinished = true;

  actions.vomit1.clampWhenFinished = true;
   actions.vomit2.clampWhenFinished = true;
   actions.vomit3.clampWhenFinished = true;

   if (!an2.actions?.gonnaAction.isRunning()) {
   an2.actions?.gonnaAction.play();
  }

    actions?.still2.play();
    actions?.still3.play();
    actions?.still4.play();
    actions?.still5.play();

    if (!actions?.toy.isRunning()) {
      actions?.toy.play();
    }


    if (!actions?.opentoy.isRunning()) {
      actions?.opentoy.play();
    }





    if (shop == true && isVisible && ding==false) {
      playDingdong();

      setDing( true);
    }
    if (!actions?.open1.isRunning()&& box == false) {
      actions?.still1.play();
    }


    if (!actions?.end1.isRunning()) {
      actions?.still6.play();
    }

    if (webmail == false && ani == 1 ) {
      if (!actions?.sitting1.isRunning()) {
        actions?.sitting1.reset().play();
      }
      if (!actions?.sitting2.isRunning()) {
        actions?.sitting2.reset().play();
      }
      if (!actions?.sitting3.isRunning()) {
        actions?.sitting3.reset().play();
      }

    }

    if (webmail == true && ani == 1 && !actions?.walking4.isRunning() && isVisible) {
      actions?.sitting1.stop();
      actions?.sitting2.stop();
      actions?.sitting3.stop();
      if (!actions?.walking1.isRunning()) {

        actions?.walking1.reset().play();
      }

      if (!actions?.walking3.isRunning()) {
      
        actions?.walking3.reset().play();
      }

      if (!actions?.walking2.isRunning()) {
     
        actions?.walking2.reset().play();
      }

      setAni(2);
    }

  });
  const openBox = (e) => {
    if (box == false) {

      actions?.still1.stop();
      actions?.open1.reset().play();

     setBox(true)
    }
    if (ani == 3 && !actions?.end1.isRunning()) {
      actions?.end1.reset().crossFadeFrom(actions?.walking4, 0.2, true).play();

      actions?.walking2.stop();
      actions?.end2.reset().play();


      actions?.still6.stop();
      actions?.end3.reset().play();

  actions.vomit1.reset().play();
    actions.vomit2.reset().play(); 
   actions.vomit3.reset().play(); 
 


   setTimeout(() => {
    z1.current.visible =true;
    z2.current.visible =true;
    z3.current.visible =true;
    playGurgle()
  }, "4000");
  
    }
  };

  //const texture1 = useTexture("../models/RoomScene1.png");
 const texture1 = useKTX2("../models/RoomScene1.ktx2", "../libs/basis/");
  texture1.channel = 1;
  texture1.flipY = false;
  texture1.colorSpace = THREE.SRGBColorSpace;

   //const texture2 = useTexture("../models/RoomScene2.png");
 const texture2 = useKTX2("../models/RoomScene2.ktx2", "../libs/basis/");
  texture2.flipY = false;
 texture2.channel = 1;
  texture2.colorSpace = THREE.SRGBColorSpace;

   // const texture3 = useTexture("../models/RoomScene3.png");
  const texture3 = useKTX2("../models/RoomScene3.ktx2", "../libs/basis/");
  texture3.flipY = false;
 texture3.channel = 1;
  texture3.colorSpace = THREE.SRGBColorSpace;


  // const texture4 = useTexture("../models/RoomScene4.png");
 const texture4 = useKTX2("../models/RoomScene4.ktx2", "../libs/basis/");
  texture4.flipY = false;
texture4.channel = 1;
  texture4.colorSpace = THREE.SRGBColorSpace;


  const material1 = new THREE.MeshStandardMaterial();
  //material1.transparent = true;
  material1.map = texture1;
// material1.side = THREE.DoubleSide;
 // material1.alphaTest = 0.5;

  const material2 = new THREE.MeshStandardMaterial();
  material2.transparent = false;
  material2.map = texture2;
//material2.side = THREE.DoubleSide;
  material2.alphaTest = 0.5;

  const material3 = new THREE.MeshStandardMaterial();
  material3.transparent = true;
  material3.map = texture3;
  material3.side = THREE.DoubleSide;
  material3.alphaTest = 0.5;

  const material4 = new THREE.MeshPhongMaterial({ color: 0x0af415,specular:0x0af415});
material4.shininess= 90;


const material5 = new THREE.MeshStandardMaterial();
material5.transparent = true;
material5.map = texture4;
material5.side = THREE.DoubleSide;
material5.alphaTest = 0.5;


 // material4.transparent = true;
 // material4.opacity = 0.8;
  // material3.map = texture3;
  // material3.side = THREE.DoubleSide;
  //material4.alphaTest = 0.5;

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  const [zoomed, setZoomed] = useState(false);
  const zoomPortal = (e) => {

    if (zoomed == false) {
      setZoomed(true);
      camera.current.zoom(frame, false);
    } else {
      setZoomed(false);
      camera.current.clear();
    }
    e.stopPropagation();
  };

  const [ding, setDing] = useState(false);


  const [banana, setBanana] = useState(true);
  const changebanana = (e) => {

    if (banana== false) {
      setBanana( true);
    } else {
      setBanana( false );
    }
  


   e.stopPropagation();
 };


 const gira = (e) => {


   if (!actions?.corpoAction.isRunning()) {
   actions?.corpoAction.play();
  
  }

  if (!actions?.aliAction.isRunning()) {
    actions?.aliAction.play();
   
   } 

 e.stopPropagation();
};

const openToy = (e) => {


  if (!actions?.toy.isRunning()) {
    actions.toy.paused=false;
  }


  if (!actions?.opentoy.isRunning()) {
    actions.opentoy.paused=false;


    


  }


  e.stopPropagation();
 };
 const changefreccia= (e) => {


freccia.current.setEnabled(true)


  e.stopPropagation();
 };
 const getRandom = (min, max) => {
  return Math.random() * (max - min) + min
  }

 const changeball= (e) => {

  ball1.current.applyImpulse({ x: 0, y: getRandom(0.0040,0.0060), z: 0 }, true);
  ball2.current.applyImpulse({ x: 0, y: getRandom(0.0040,0.0060), z: 0 }, true);
  ball3.current.applyImpulse({ x: 0, y: getRandom(0.0040,0.0060), z: 0 }, true);
  ball4.current.applyImpulse({ x: 0, y: getRandom(0.0040,0.0060), z: 0 }, true);
  ball5.current.applyImpulse({ x: 0, y: getRandom(0.0040,0.0060), z: 0 }, true);
  ball6.current.applyImpulse({ x: 0, y: getRandom(0.0040,0.0060), z: 0 }, true);
  ball7.current.applyImpulse({ x: 0, y: getRandom(0.0040,0.0060), z: 0 }, true);



/*   ball1.current.setEnabled(true)
  ball2.current.setEnabled(true)
  ball3.current.setEnabled(true)
  ball4.current.setEnabled(true)
  ball5.current.setEnabled(true)
  ball6.current.setEnabled(true)
  ball7.current.setEnabled(true)
   */
    e.stopPropagation();
   };


  const openWebmail = (e) => {
    window.open("/webmail", "_blank");
    e.stopPropagation();
  };



  const playHundi= (e) => {


    if (!actions?.hundiAction.isRunning()) {
      actions.hundiAction.paused=false;
      actions.hundiAction.play();
    }
  
  


    e.stopPropagation();
  };

  useFrame((state) => {
    state.gl.autoClear=true
    
    state.gl.setRenderTarget(buffer2)
   
    

    glass1.current.visible = false;
    glass2.current.visible = false;

    state.gl.render(state.scene, state.camera)
 
   
 



    screen.current.map = buffer2.texture;

   // state.gl.setRenderTarget(buffer2)

 //state.gl.render(state.scene, state.camera)
 //state.gl.outputEncoding = THREE.GammaEncoding

 state.gl.setRenderTarget(buffer)
 state.gl.render(state.scene, state.camera)
  
  //  effects.current.setRenderer(buffer2)
effects.current.render()

    screen.current.map =  buffer3.texture;
   // state.gl.clearTarget(buffer, true,true);

// console.log(  state.gl)
    

state.gl.setRenderTarget(null)
glass1.current.visible = true
glass2.current.visible = true
     const x = 180-camera.current.getx()
     const y = 360-camera.current.gety()


    //  screen.current.rotation.set(
    //   0,
    //   180- x,
    //   y
    // );
  fluffy.current.rotation.set(
    0,
    360.5 - x,
    y - 0.3
  );
  controlcamera.current.rotation.set(
    0,
   90+x,
    0
  );
  coso.current.rotation.set(
    0,
   360- x,
    0
  );

/*   hundi.current.rotation.set(
    0,
    180-x,
    0
  ); */
    monster.current.position.set(
      cup.current.position.x,
      cup.current.position.y + 5,
      cup.current.position.z
    );
    monster.current.rotation.set(
      cup.current.rotation.x,
      cup.current.rotation.y,
      cup.current.rotation.z
    );

  })

  // const { position,rotation,scale } = useControls({
  //   position: {
  //     value: { x: 6.6, y: 1.6, z: 8.7  },
  //     step: 0.1,
  //   },
  //   rotation: {
  //     value: { x: 0, y: 10, z: 0  },
  //     step: 0.1,
  //   },
  //   scale: {
  //     value: { x: 1, y: 1, z: 1  },
  //     step: 0.1,
  //   },
  // })

  return (
    <>


<Bvh firstHitOnly> 
<Detailed distances={[0, 1,3]} >


      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
  

          <Physics debug={false}  gravity={[0, -7, 0]}  allowSleep={true}>
     
     
  
          <RigidBody
  visible={banana}
         colliders={"hull"}
         ref={freccia}
         onPointerEnter={() => {
          document.body.style.cursor = "url('/images/cursor.png'), pointer";
        }}
        onPointerLeave={() => {
          document.body.style.cursor = "default";
        }}
        onClick={changefreccia}
        mass={10}
       // restitution={0}
         >
            <group name="freccia"
            
          
            position={[
              roomModelMaterial.nodes.freccia.position.x,
              roomModelMaterial.nodes.freccia.position.y,
              roomModelMaterial.nodes.freccia.position.z,
            ]}>
              <mesh
                name="Plane010"
                castShadow
                geometry={roomModelMaterial.nodes.Plane010.geometry}
                material={roomModelMaterial.materials.nero}
              />
              <mesh
                name="Plane010_1"
                geometry={roomModelMaterial.nodes.Plane010_1.geometry}
                material={roomModelMaterial.materials.bianco}
              />
            </group>
          
            </RigidBody>


      
            <RigidBody
  visible={banana}
         colliders={"ball"}
         ref={ball1}
 
         >
            <mesh
          name="ball1"
    
          geometry={roomModelMaterial.nodes.ball1.geometry}
          material={roomModelMaterial.materials['Material.010']}
          position={[
            roomModelMaterial.nodes.ball1.position.x,
            roomModelMaterial.nodes.ball1.position.y,
            roomModelMaterial.nodes.ball1.position.z,
          ]}
        />

</RigidBody>


<RigidBody
  visible={banana}
         colliders={"ball"}
         ref={ball2}
         
         >
        <mesh
          name="ball2"
    
          geometry={roomModelMaterial.nodes.ball2.geometry}
          material={roomModelMaterial.materials['Material.009']}
          position={[
            roomModelMaterial.nodes.ball2.position.x,
            roomModelMaterial.nodes.ball2.position.y,
            roomModelMaterial.nodes.ball2.position.z,
          ]}
        />

</RigidBody>


<RigidBody
  visible={banana}
         colliders={"ball"}
         ref={ball3}
         
         >
        <mesh
          name="ball3"
   
          geometry={roomModelMaterial.nodes.ball3.geometry}
          material={roomModelMaterial.materials['Material.011']}
          position={[
            roomModelMaterial.nodes.ball3.position.x,
            roomModelMaterial.nodes.ball3.position.y,
            roomModelMaterial.nodes.ball3.position.z,
          ]}
        />

</RigidBody>


<RigidBody
  visible={banana}
         colliders={"ball"}
         ref={ball4}
         
         >

        <mesh
          name="ball4"
     
          geometry={roomModelMaterial.nodes.ball4.geometry}
          material={roomModelMaterial.materials['Material.013']}
          position={[
            roomModelMaterial.nodes.ball4.position.x,
            roomModelMaterial.nodes.ball4.position.y,
            roomModelMaterial.nodes.ball4.position.z,
          ]}
        />

</RigidBody>


<RigidBody
  visible={banana}
         colliders={"ball"}
         ref={ball5}
         
         >

        <mesh
          name="ball5"
  
          geometry={roomModelMaterial.nodes.ball5.geometry}
          material={roomModelMaterial.materials['Material.012']}
          position={[
            roomModelMaterial.nodes.ball5.position.x,
            roomModelMaterial.nodes.ball5.position.y,
            roomModelMaterial.nodes.ball5.position.z,
          ]}
        />

</RigidBody>

<RigidBody
  visible={banana}
         colliders={"ball"}
         ref={ball6}
         
         >
        <mesh
          name="ball6"
      
          geometry={roomModelMaterial.nodes.ball6.geometry}
          material={roomModelMaterial.materials['Material.014']}
          position={[
            roomModelMaterial.nodes.ball6.position.x,
            roomModelMaterial.nodes.ball6.position.y,
            roomModelMaterial.nodes.ball6.position.z,
          ]}
        />
  </RigidBody>

<RigidBody
  visible={banana}
         colliders={"ball"}
         ref={ball7}
         
 
         >


        <mesh
          name="ball7"
   
          geometry={roomModelMaterial.nodes.ball7.geometry}
          material={roomModelMaterial.materials['Material.015']}
          position={[
            roomModelMaterial.nodes.ball7.position.x,
            roomModelMaterial.nodes.ball7.position.y,
            roomModelMaterial.nodes.ball7.position.z,
          ]}
        />
        

        </RigidBody>
     
         

            <RigidBody
              type="fixed"
              restitution={1.05}
            
              colliders={"trimesh"}
            >
              <mesh
                geometry={nodes.room.geometry}
                position={[
                  nodes.room.position.x,
                  nodes.room.position.y,
                  nodes.room.position.z,
                ]}
                scale={nodes.room.scale}
                material={material1}
                visible={banana}
              />


            </RigidBody>
          <RigidBody
              type="fixed"
              restitution={1.05}
             
            >
          

<CuboidCollider args={[12, 0.3, 12]} position={[0, -3.6, 0]} />
            </RigidBody> 

            </Physics>
            <mesh
                geometry={nodes.mobili.geometry}
                position={[
                  nodes.mobili.position.x,
                  nodes.mobili.position.y,
                  nodes.mobili.position.z,
                ]}
                scale={nodes.mobili.scale}
                material={material2}
                visible={banana}
              />
            <group name="emo"
                 position={[
                  roomModelMaterial.nodes.emo.position.x,
                  roomModelMaterial.nodes.emo.position.y,
                  roomModelMaterial.nodes.emo.position.z,
                ]}
                visible={banana}
            >
              <mesh
                name="Cube015"
                geometry={roomModelMaterial.nodes.Cube015.geometry}
                material={roomModelMaterial.materials["Principled Yellow Shader"]}
              />
              <mesh
                name="Cube015_1"
                geometry={roomModelMaterial.nodes.Cube015_1.geometry}
                material={roomModelMaterial.materials["Pred.002"]}
              />
              <mesh
                name="Cube015_2"
                geometry={roomModelMaterial.nodes.Cube015_2.geometry}
                material={roomModelMaterial.materials["Pred.003"]}
              />
              <mesh
                name="Cube015_3"
                geometry={roomModelMaterial.nodes.Cube015_3.geometry}
                material={roomModelMaterial.materials["Principled White.001"]}
              />
            </group>

            <mesh
              name="vaso"
              visible={banana}
              geometry={roomModelMaterial.nodes.vaso.geometry}
              material={roomModelMaterial.materials.vaso}
              position={[
                roomModelMaterial.nodes.vaso.position.x,
                roomModelMaterial.nodes.vaso.position.y,
                roomModelMaterial.nodes.vaso.position.z,
              ]}
            />


            <mesh
              name="curves"
              visible={banana}
              geometry={roomModelMaterial.nodes.curves.geometry}
              material={roomModelMaterial.materials.blu}
            />

            <group name="meshes"   visible={banana}>
              <mesh
                name="Cube004"
                geometry={roomModelMaterial.nodes.Cube004.geometry}
                material={roomModelMaterial.materials.grigio}
              />
              <mesh
                name="Cube004_1"
                geometry={roomModelMaterial.nodes.Cube004_1.geometry}
                material={roomModelMaterial.materials.bianco}
              />
            </group>
            <group name="gonna"
              visible={banana}
            position={[
              roomModelMaterial.nodes.gonna.position.x,
              roomModelMaterial.nodes.gonna.position.y,
              roomModelMaterial.nodes.gonna.position.z,
            ]}
            >
              <mesh
                name="Circle"
                geometry={roomModelMaterial.nodes.Circle.geometry}
                material={roomModelMaterial.materials.velvet}
              />
              <mesh
                name="Circle_1"
                geometry={roomModelMaterial.nodes.Circle_1.geometry}
                material={roomModelMaterial.materials["velvet.white"]}
              />
            </group>
     
            <mesh
              name="coso"
            ref={coso}
              geometry={nodes.coso.geometry}
              material={material5}
              position={[
                nodes.coso.position.x,
                nodes.coso.position.y,
                nodes.coso.position.z,
              ]}
              rotation={[
                nodes.coso.rotation.x,
                nodes.coso.rotation.y,
                nodes.coso.rotation.z,
              ]}
              scale={nodes.coso.scale}
              visible={banana}
            />

            <group
              name="fluffy"
              ref={fluffy}

            
              onPointerEnter={() => {
                document.body.style.cursor = "url('/images/cursor.png'), pointer";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={changeball}
              visible={banana}

                        
          position={[
            nodes.fluffy.position.x,
            nodes.fluffy.position.y,
            nodes.fluffy.position.z,
          ]}
          rotation={[
            nodes.fluffy.rotation.x,
            nodes.fluffy.rotation.y,
            nodes.fluffy.rotation.z,
          ]}
          scale={nodes.fluffy.scale}

            >

<mesh
          name="face1"
        material={material5}
          geometry={nodes.face1.geometry}
          
          position={[
            nodes.face1.position.x,
            nodes.face1.position.y,
            nodes.face1.position.z,
          ]}
          rotation={[
            nodes.face1.rotation.x,
            nodes.face1.rotation.y,
            nodes.face1.rotation.z,
          ]}
          scale={nodes.face1.scale}
        />
        <mesh
          name="fluffo"
          material={material5}
          geometry={nodes.fluffo.geometry}
          position={[
            nodes.fluffo.position.x,
            nodes.fluffo.position.y,
            nodes.fluffo.position.z,
          ]}
          rotation={[
            nodes.fluffo.rotation.x,
            nodes.fluffo.rotation.y,
            nodes.fluffo.rotation.z,
          ]}
          scale={nodes.fluffo.scale}
       
  
          />
          
          

</group>

            <mesh
              name="oggetti2"
              geometry={nodes.oggetti2.geometry}
              material={material5}
              position={[
                nodes.oggetti2.position.x,
                nodes.oggetti2.position.y,
                nodes.oggetti2.position.z,
              ]}
              rotation={[
                nodes.oggetti2.rotation.x,
                nodes.oggetti2.rotation.y,
                nodes.oggetti2.rotation.z,
              ]}
              scale={nodes.oggetti2.scale}
              visible={banana}
            />

            <mesh
              name="hundi"
             // ref={hundi}
              geometry={nodes.hundi.geometry}
              material={material5}
              position={[
                nodes.hundi.position.x,
                nodes.hundi.position.y,
                nodes.hundi.position.z,
              ]}
              rotation={[
                nodes.hundi.rotation.x,
                nodes.hundi.rotation.y,
                nodes.hundi.rotation.z,
              ]}
              scale={nodes.hundi.scale}
              visible={banana}

              onPointerEnter={() => {
                document.body.style.cursor = "url('/images/cursor.png'), pointer";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={playHundi}

            />

            <Float
              speed={0.5} // Animation speed, defaults to 1
              rotationIntensity={0} // XYZ rotation intensity, defaults to 1
              floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
              floatingRange={[0, 0.2]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
            >
              <mesh
                name="skull"
                geometry={nodes.skull.geometry}
                material={material5}
                position={[
                  nodes.skull.position.x,
                  nodes.skull.position.y,
                  nodes.skull.position.z,
                ]}
                rotation={[
                  nodes.skull.rotation.x,
                  nodes.skull.rotation.y,
                  nodes.skull.rotation.z,
                ]}
                scale={nodes.skull.scale}
                visible={banana}
              />
            </Float>

            <Float
              speed={0.5} // Animation speed, defaults to 1
              rotationIntensity={2} // XYZ rotation intensity, defaults to 1
              floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
              floatingRange={[1, 1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
            >
              <mesh
                name="monster"
                ref={monster}
                geometry={nodes.monster.geometry}
                position={[
                  nodes.CupContainer.position.x,
                  nodes.monster.position.y,
                  nodes.CupContainer.position.z,
                ]}
                rotation={[
                  nodes.monster.rotation.x,
                  nodes.monster.rotation.y,
                  nodes.monster.rotation.z,
                ]}
                scale={nodes.monster.scale}
                visible={banana}
              >
                <MeshDistortMaterial
                  map={texture3}
                  metalness={0.6}
                  roughness={0.6}
                  distort={0.6}
                  speed={1}
                />
              </mesh>
            </Float>

            <mesh
              name="luce"
              geometry={nodes.luce.geometry}
              material={material3}
              position={[
                nodes.luce.position.x,
                nodes.luce.position.y,
                nodes.luce.position.z,
              ]}
              onPointerEnter={() => {
                document.body.style.cursor = "url('/images/cursor.png'), pointer";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={changebanana}
            />

            <group
              name="products"
              position={[
                nodes.products.position.x,
                nodes.products.position.y,
                nodes.products.position.z,
              ]}
              rotation={[
                nodes.products.rotation.x,
                nodes.products.rotation.y,
                nodes.products.rotation.z,
              ]}
              visible={banana}
            >
              <mesh
                name="p1"
                geometry={nodes.p1.geometry}
                material={material3}
                visible={p1}
              />

              <mesh
                name="p2"
                geometry={nodes.p2.geometry}
                material={material3}
                visible={p2}
              />
              <mesh
                name="p3"
                geometry={nodes.p3.geometry}
                material={material3}
                visible={p3}
              />
              <mesh
                name="p4"
                geometry={nodes.p4.geometry}
                material={material3}
                visible={p4}
              />
            </group>

            <mesh
              name="telefono"
              geometry={nodes.telefono.geometry}
              position={[
                nodes.telefono.position.x,
                nodes.telefono.position.y,
                nodes.telefono.position.z,
              ]}
              rotation={[
                nodes.telefono.rotation.x,
                nodes.telefono.rotation.y,
                nodes.telefono.rotation.z,
              ]}
              scale={nodes.telefono.scale}
              material={material3}
              onPointerEnter={() => {
                document.body.style.cursor = "url('/images/cursor.png'), pointer";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={openWebmail}
            />

            <group
              name="CupContainer"
              ref={cup}
              position={[
                nodes.CupContainer.position.x,
                nodes.CupContainer.position.y,
                nodes.CupContainer.position.z,
              ]}
              rotation={[
                nodes.CupContainer.rotation.x,
                nodes.CupContainer.rotation.y,
                nodes.CupContainer.rotation.z,
              ]}
              scale={nodes.CupContainer.scale}
            >
              <group name="Cup">
                <skinnedMesh
                  name="body"
                  geometry={nodes.body.geometry}
                  material={material3}
                  skeleton={nodes.body.skeleton}
                  visible={banana}
                  position={[
                    nodes.body.position.x,
                    nodes.body.position.y,
                    nodes.body.position.z,
                  ]}
                  rotation={[
                    nodes.body.rotation.x,
                    nodes.body.rotation.y,
                    nodes.body.rotation.z,
                  ]}
                />

                <skinnedMesh
                  name="eyel"
                  geometry={nodes.eyel.geometry}
                  material={material3}
                  skeleton={nodes.eyel.skeleton}
                  position={[
                    nodes.eyel.position.x,
                    nodes.eyel.position.y,
                    nodes.eyel.position.z,
                  ]}
                  rotation={[
                    nodes.eyel.rotation.x,
                    nodes.eyel.rotation.y,
                    nodes.eyel.rotation.z,
                  ]}
                />
                <skinnedMesh
                  name="eyer"
                  geometry={nodes.eyer.geometry}
                  material={material3}
                  skeleton={nodes.eyer.skeleton}
                  position={[
                    nodes.eyer.position.x,
                    nodes.eyer.position.y,
                    nodes.eyer.position.z,
                  ]}
                  rotation={[
                    nodes.eyer.rotation.x,
                    nodes.eyer.rotation.y,
                    nodes.eyer.rotation.z,
                  ]}
                />
                <skinnedMesh
                  name="hair"
                  geometry={nodes.hair.geometry}
                  material={material3}
                  skeleton={nodes.hair.skeleton}
                  position={[
                    nodes.hair.position.x,
                    nodes.hair.position.y,
                    nodes.hair.position.z,
                  ]}
                  rotation={[
                    nodes.hair.rotation.x,
                    nodes.hair.rotation.y,
                    nodes.hair.rotation.z,
                  ]}
                />
                <skinnedMesh
                  name="limbs"
                  geometry={nodes.limbs.geometry}
                  material={material3}
                  skeleton={nodes.limbs.skeleton}
                  visible={banana}
                  position={[
                    nodes.limbs.position.x,
                    nodes.limbs.position.y,
                    nodes.limbs.position.z,
                  ]}
                  rotation={[
                    nodes.limbs.rotation.x,
                    nodes.limbs.rotation.y,
                    nodes.limbs.rotation.z,
                  ]}
                />
                <skinnedMesh
                  name="mouth"
                  geometry={nodes.mouth.geometry}
                  material={material3}
                  skeleton={nodes.mouth.skeleton}
                  position={[
                    nodes.mouth.position.x,
                    nodes.mouth.position.y,
                    nodes.mouth.position.z,
                  ]}
                  rotation={[
                    nodes.mouth.rotation.x,
                    nodes.mouth.rotation.y,
                    nodes.mouth.rotation.z,
                  ]}
                />
                <skinnedMesh
                  ref={v1}
                  name="v1"
                  geometry={nodes.v1.geometry}
                  material={material4}
                  skeleton={nodes.v1.skeleton}
                  visible={false}
                />

                <mesh
                  name="z1"
                  ref={z1}
                  visible={false}
                  geometry={nodes.z1.geometry}
                  material={material4}
                  position={[
                    nodes.z1.position.x,
                    nodes.z1.position.y,
                    nodes.z1.position.z,
                  ]}
                  rotation={[
                    nodes.z1.rotation.x,
                    nodes.z1.rotation.y,
                    nodes.z1.rotation.z,
                  ]}
                  scale={[nodes.z1.scale.x, nodes.z1.scale.y, nodes.z1.scale.z]}
                />
                <mesh
                  name="z2"
                  ref={z2}
                  visible={false}
                  geometry={nodes.z2.geometry}
                  material={material4}
                  position={[
                    nodes.z2.position.x,
                    nodes.z2.position.y,
                    nodes.z2.position.z,
                  ]}
                  rotation={[
                    nodes.z2.rotation.x,
                    nodes.z2.rotation.y,
                    nodes.z2.rotation.z,
                  ]}
                  scale={[nodes.z2.scale.x, nodes.z2.scale.y, nodes.z2.scale.z]}
                />

                <mesh
                  name="z3"
                  ref={z3}
                  visible={false}
                  geometry={nodes.z3.geometry}
                  material={material4}
                  position={[
                    nodes.z3.position.x,
                    nodes.z3.position.y,
                    nodes.z3.position.z,
                  ]}
                  rotation={[
                    nodes.z3.rotation.x,
                    nodes.z3.rotation.y,
                    nodes.z3.rotation.z,
                  ]}
                  scale={[nodes.z3.scale.x, nodes.z3.scale.y, nodes.z3.scale.z]}
                />

                <primitive object={nodes.mixamorigHips} />
              </group>
            </group>

            <group
              name="box"
              visible={shop}
              onPointerEnter={() => {
                document.body.style.cursor = "url('/images/cursor.png'), pointer";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={openBox}
            >
              <skinnedMesh
                name="boxmesh"
                geometry={nodes.boxmesh.geometry}
                material={material3}
                skeleton={nodes.boxmesh.skeleton}
              />
              <primitive object={nodes.Bone} />
              <primitive object={nodes.neutral_bone} />
            </group>

            <mesh
              name="corpo"
              geometry={nodes.corpo.geometry}
              position={[
                nodes.corpo.position.x,
                nodes.corpo.position.y,
                nodes.corpo.position.z,
              ]}
              rotation={[
                nodes.corpo.rotation.x,
                nodes.corpo.rotation.y,
                nodes.corpo.rotation.z,
              ]}
              material={material3}
              visible={banana}
          
              onPointerEnter={() => {
                document.body.style.cursor = "url('/images/cursor.png'), pointer";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={gira}

            />

            <mesh
              geometry={nodes.bilder.geometry}
              position={[
                nodes.bilder.position.x,
                nodes.bilder.position.y,
                nodes.bilder.position.z,
              ]}
              rotation={[
                nodes.bilder.rotation.x,
                nodes.bilder.rotation.y,
                nodes.bilder.rotation.z,
              ]}
              scale={nodes.bilder.scale}
              material={material3}
              visible={banana}
            />

            <mesh
              geometry={nodes.roba.geometry}
              position={[
                nodes.roba.position.x,
                nodes.roba.position.y,
                nodes.roba.position.z,
              ]}
              scale={nodes.roba.scale}
              material={material1}
              visible={banana}
            />

            <mesh
              geometry={nodes.wobble.geometry}
              position={[
                nodes.wobble.position.x,
                nodes.wobble.position.y,
                nodes.wobble.position.z,
              ]}
              scale={nodes.wobble.scale}
              visible={banana}
            >
              <MeshDistortMaterial distort={0.6} speed={0.3} map={texture1} />
            </mesh>


           <group ref={glass1}>

            <mesh
              name="ali"
              geometry={nodes.ali.geometry}
              position={[
                nodes.ali.position.x,
                nodes.ali.position.y,
                nodes.ali.position.z,
              ]}
              rotation={[
                nodes.ali.rotation.x,
                nodes.ali.rotation.y,
                nodes.ali.rotation.z,
              ]}
              visible={banana}
            >
              <MeshTransmissionMaterial
                buffer={buffer.texture}
                meshPhysicalMaterial={false}
                backside={false}
                color={"#c9ffa1"}
                bg={"#839681"}
                thickness={1.5}
                samples={10}
                resolution={1024}
                roughness={0}
                ior={2}
                chromaticAberration={0.06}
                anisotropy={0.1}
                distortion={0}
                distortionScale={0.3}
                temporalDistortion={0.5}
                clearcoat={2}
                attenuationDistance={0.1}
                attenuationColor={"#ffffff"}
                envMapIntensity={0.5}
              />
            </mesh>
            </group>




            <mesh
              ref={frame}
              onPointerEnter={() => {
                document.body.style.cursor = "url('/images/cursor.png'), pointer";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={zoomPortal}
              position={[-3.5, 3.5, -10.8]}
              visible={banana}
            >
              <planeGeometry args={[6.1, 3.8]} />
              <meshStandardMaterial>
                <RenderTexture
                  attach="map"
                  width={1525}
                  height={950}
                  frames={zoomed ? Infinity : 1}
                >
                  <PortalScene />
                </RenderTexture>
              </meshStandardMaterial>
            </mesh>



    
         
      


  <mesh
            name="screen"
     
          geometry={nodes.screen1.geometry}
         // material={material3}
          position={[
            nodes.screen1.position.x,
            nodes.screen1.position.y,
            nodes.screen1.position.z,
          ]}
          rotation={[
            nodes.screen1.rotation.x,
            nodes.screen1.rotation.y,
            nodes.screen1.rotation.z,
          ]}
          scale={[
            nodes.screen1.scale.x,
            nodes.screen1.scale.y,
            nodes.screen1.scale.z,
          ]}
          visible={banana}
        >
  
           <meshStandardMaterial ref={screen} side={THREE.DoubleSide} emissiveIntensity={0.05} emissive={"#2d897f"} metalness={0} />
        </mesh>
  
        
   
     
 <group ref={glass2}>
            <mesh
              geometry={nodes.glass.geometry}
              position={[
                nodes.glass.position.x,
                nodes.glass.position.y,
                nodes.glass.position.z,
              ]}
              scale={nodes.glass.scale}
              visible={banana}
              renderOrder={-100}
              material-side={THREE.FrontSide}
            >
              <MeshTransmissionMaterial
                buffer={buffer.texture}
                meshPhysicalMaterial={false}
                backside={false}
                color={"#c9ffa1"}
                bg={"#839681"}
                thickness={1}
                samples={10}
                resolution={1024}
                roughness={0}
                ior={1.5}
                chromaticAberration={0.06}
                anisotropy={0.1}
                distortion={0}
                distortionScale={0.3}
                temporalDistortion={0.5}
                clearcoat={2}
                attenuationDistance={0.1}
                attenuationColor={"#ffffff"}
                envMapIntensity={0.5}
              />
            </mesh>
</group>


            <group
              name="toyarm"
              visible={banana}
              position={[
                nodes.toyarm.position.x,
                nodes.toyarm.position.y,
                nodes.toyarm.position.z,
              ]}
            >
              <skinnedMesh
                name="mostro"
                geometry={nodes.mostro.geometry}
                material={material5}
                skeleton={nodes.mostro.skeleton}
              />
              <primitive object={nodes.toy1} />
            </group>

            <group
              name="toy"
              visible={banana}
              position={[
                nodes.toy.position.x,
                nodes.toy.position.y,
                nodes.toy.position.z,
              ]}
              onPointerEnter={() => {
                document.body.style.cursor = "url('/images/cursor.png'), pointer";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={openToy}
            >
              <skinnedMesh
                name="toymesh"
                geometry={nodes.toymesh.geometry}
                material={material5}
                skeleton={nodes.toymesh.skeleton}
              />
              <primitive object={nodes.Bone_1} />
              <primitive object={nodes.neutral_bone_1} />
            </group>

            <mesh
              name="camera"
              visible={banana}
              ref={controlcamera}
              geometry={nodes.camera.geometry}
              material={material5}
              position={[
                nodes.camera.position.x,
                nodes.camera.position.y,
                nodes.camera.position.z,
              ]}
            />
        

         <mesh
              geometry={nodes.mirror1.geometry}
              position={[
                nodes.mirror1.position.x,
                nodes.mirror1.position.y,
                nodes.mirror1.position.z,
              ]}
              rotation={[
                nodes.mirror1.rotation.x,
                nodes.mirror1.rotation.y,
                nodes.mirror1.rotation.z,
              ]}
              scale={nodes.mirror1.scale}
            
              visible={banana}
            >
    
        <meshStandardMaterial
       // blur={[300, 300]}
          //   resolution={2048}
      // mixBlur={4}
          //   mixStrength={20}
         // depthScale={0.5}
        // minDepthThreshold={0.5}
          color="#2b4d68"
         //  metalness={0.2}
       roughness={0.3}
        //  buffer={buffer.texture}
        //  mirror={0.95}
        metalness={1}
          transparent={true}
           opacity={0.4}
       
         // alphaTest= {0.5}
        // depthToBlurRatioBias={0.25}
        //  distortion={100}
        //side={THREE.DoubleSide}
        //reflectorOffset={-50}


        />
      </mesh> 



     



<mesh
              geometry={nodes.roof.geometry}
              position={[
                nodes.roof.position.x,
                nodes.roof.position.y,
                nodes.roof.position.z,
              ]}
              rotation={[
                nodes.roof.rotation.x,
                nodes.roof.rotation.y,
                nodes.roof.rotation.z,
              ]}
              scale={nodes.roof.scale}
              material={material5}
              visible={banana}
            />


<mesh
          name="faccia"
          position={[
            nodes.faccia.position.x,
            nodes.faccia.position.y,
            nodes.faccia.position.z,
          ]}
          rotation={[
            nodes.faccia.rotation.x,
            nodes.faccia.rotation.y,
            nodes.faccia.rotation.z,
          ]}
          scale={nodes.faccia.scale}
          geometry={nodes.faccia.geometry}
          material={material5}
          visible={banana}
        />



        </group>
      </group>

      </Detailed >
      </Bvh> 


      <Html wrapperClass="fullscreen fullscreenToast">
        <Toast ref={modalRef} />{" "}
      </Html>

      <Environment
        background={banana}
        backgroundBlurriness={0.1}
        backgroundIntensity={0.05}
        files="kiara_1_dawn_1k.hdr"
        path="../models/"
       // environmentRotation={[Math.PI / 2, Math.PI / 2, 0]} 
      />




      <Postprocessing />

      
      <CameraControlsBox ref={camera} />

      <EffectComposer 
    
    
    enabled={false} 
    
    
    ref={effects} 
    
    //autoRenderToScreen={false}
    > 







{/* 
 <HueSaturation
    blendFunction={BlendFunction.NORMAL} // blend mode
    hue={375} // hue in radians
    saturation={-0.3} // saturation in radians
  />  */}


{/* <ChromaticAberration
 offset={[0.004, 0.004] } // color offset

/>  */}

 <Sepia
    intensity={1} // sepia intensity
   // blendFunction={BlendFunction.NORMAL} // blend mode
  /> 

<Scanline density={0.05} 
         
         //  blendFunction={BlendFunction.HARD_LIGHT} 
           
           opacity={0.15}/> 
 {/* <BrightnessContrast
    brightness={0.1} // brightness. min: -1, max: 1
    contrast={0.2} // contrast: min -1, max: 1
  />   */}
 




 <Noise opacity={0.3} />  



        </EffectComposer> 



    </>
  );
}
