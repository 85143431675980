import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import messages from "../data/messages.json";

const initialState = {
  scene: "TitleScene",
  webmail: false,
  shop:false,
  p1: false,
  p2: false,
  p3: false,
  p4: false,
cart: [],
messages:messages,
};

export const useStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      reset: () => {
        set(initialState);
      },
    }),
    {
      name: "boenk-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
