import React, { forwardRef, useImperativeHandle, useState, useEffect} from "react";
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

import useSound from 'use-sound';
import click01 from '/sound/click01.wav';
import click02 from '/sound/click02.wav';
import error01 from '/sound/error01.wav';
import error02 from '/sound/error02.wav';
import error03 from '/sound/error03.wav';
import error04 from '/sound/error04.wav';



const Toast = forwardRef((props, ref) => {

  const [playClick01] = useSound(click01);
  const [playClick02] = useSound(click02);

  const [playError01] = useSound(error01);
  const [playError02] = useSound(error02);
  const [playError03] = useSound(error03);
  const [playError04] = useSound(error04);





  useImperativeHandle(ref, () => ({
   show() {
        showF()
    }
}));





   const openWebmail = (e) => {
   window.open("/webmail", "_blank");
    e.stopPropagation()
   };



   const openShop = (e) => {
    window.open("/shop", "_blank");
     e.stopPropagation()
    };


useEffect(() => {
 
  showF ()
  return () => {
  
  }
}, []);



 






  const showF = () => {



    






  setTimeout(() => {
    var toastEl1 = document.getElementById('toast1')
    var toast1 = bootstrap.Toast.getOrCreateInstance(toastEl1) 
        toast1.show();
        playError01()
}, 6000);

    
      setTimeout(() => {
      var toastEl2 = document.getElementById('toast2')
    var toast2 = bootstrap.Toast.getOrCreateInstance(toastEl2) 
        toast2.show();
        playError01()
  }, 20000);

            

   
  
    
  }




  return (
    <>


<div className="toast-container position-absolute top-0 end-0 p-2 p-md-3">

    
  <div id="toast1" className="toast" role="alert"  data-bs-autohide="false">
    <div className="toast-header">

      <img src="/favicons/webmail/favicon-32x32.png" className="rounded me-2"  />

      <strong className="me-auto">Bönkmail</strong>
 
      <small className="text-muted">Today, 15.16</small>
    
      <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div className="toast-body">
    You have a new message from CryptoBanana 555APE <div style={{textAlign:"right"}} onClick={openWebmail}><span className="btn btn-primary"> Show</span></div>


    </div>

  </div>

 <div id="toast2" className="toast" role="alert"  data-bs-autohide="false">
    <div className="toast-header">
   
    
      <strong className="me-auto">Sponsored content</strong>


      <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div className="toast-body p-0">
 
    <img  src="/images/shop/banner.png" width={"600px"} height={"auto"} onClick={openShop}/>
  
  </div>

</div>

  {/*<div id="toast3" className="toast" role="alert"  data-bs-autohide="false">
    <div className="toast-header">
      <img src="/favicons/webmail/favicon-32x32.png" className="rounded me-2" />
    
      <strong className="me-auto">Bönkmail</strong>

      <small className="text-muted">Today, 12.11</small>
      <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div className="toast-body">
    You have a new message from LinkedIn <div style={{cursor: "url('/images/cursor.png'), pointer",textDecoration: "underline", textAlign:"right"}} onClick={openWebmail}> Show &rarr;</div>
  </div>

</div> */}

</div>
  </>

  );
});

export default Toast;
