import "./style.css";

import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import Game from "./Game.jsx";
import { useGLTF} from "@react-three/drei";
//useKTX2.basisPath="../libs/basis/";
//PRELOAD
useGLTF.preload("../models/TitleScene.glb");
//useKTX2.preload("../models/TitleScene.ktx2");
useGLTF.preload("../models/RoomScene.glb");
useGLTF.preload("../models/RoomSceneMaterial.glb");
//useTexture.preload("../models/RoomScene1.png");
//useTexture.preload("../models/RoomScene2.png");
//useTexture.preload("../models/RoomScene3.png");
//useTexture.preload("../models/RoomScene4.png");
useGLTF.preload("../models/PortalScene.glb");
//useTexture.preload("../models/PortalScene.png");
useGLTF.preload("../models/TripScene.glb");
//useTexture.preload("../models/TripScene.png");

createRoot(document.querySelector("#root")).render(
  <StrictMode>
    <Game />
  </StrictMode>
);
