import { SpriteAnimator, Html, Plane , Text,useSpriteAnimator} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

import { useRef, useEffect,useState } from "react";
import { useSpring, a,  } from '@react-spring/three'
import { useStore } from "../components/GameStore.jsx";
import Modal from "../components/Modal.jsx";
import CameraControlsBox from "../components/CameraControlsBox02.jsx";
import Postprocessing from "../components/Postprocessing02.jsx";


export default function UpdateScene(props) {


  const [count, setCount] = useState(0)
  const [clicked, setClicked] = useState(false)


  function SpriteController() {
    const sprite = useSpriteAnimator()
  


    useFrame(() => {
      if (sprite  ) {

    sprite.current = count *  1/11 + 0.01
   
 
      }
    })
  
    return null
  }

  

  const { scale , positionX} = useSpring({
    scale: clicked ? 0.8 : 0.1,
    positionX: clicked ? -0.08 : -0.34,

    delay: 1000,
   config: { duration: 5000    },


   onRest: (e) => {
       if (e.finished === true) {
        generateError();
       }
   },


  })

  useEffect(() => {
    setCount(0)

    setClicked(true)
    return () => {

    };
  }, []);



  const modalRefFinal = useRef();
  const errors = ["error1","error2", "error3", "error4", "error5", "error6", "error7", "error8", "error9", "error10"];

  const modalsRef = useRef([]);

  const startGame = () => {
    useStore.setState({ scene: "RoomScene" });
  };
  const generateModal = () => {


    modalsRef.current[count].show()
    setCount(count + 1)
  };
  const generateError = () => {





    if (count >= errors.length) {
          setCount(count + 1)
      modalRefFinal.current.show()
    } else {

     
            generateModal()
   
   
   
    }
  };

  return (
    <>
        



<color args={["teal"]} attach="background" /> 
      <SpriteAnimator
   
        scale={0.8}
       // fps={14}
        position={[0, 0.5, 0]}
        startFrame={0}
       
  
     autoPlay={true}
        // numberOfFrames={14}
       // loop={true}
        // pause={true}
        // alphaTest={0.001}
      asSprite={false}
        textureImageURL={"./images/loading.png"}
        textureDataURL={"./images/loading.json"}
      
      
     > <SpriteController /> </SpriteAnimator> 
<Plane
 position={[0, -0.495,0]}
  args={[0.8, 0.070]} // Width, height, depth. Default is [1, 1, 1]
material-color={"black"}
 
></Plane>

<a.group  scale={scale}   position-x={positionX} position-y={ -0.48} position-z={0.1}>
<Plane
 position={[0, 0, 0]}
  args={[0.75, 0.025]} // Width, height, depth. Default is [1, 1, 1]
  material-color={"white"}

  
></Plane></a.group>

<Text fontSize={0.15}  position={[0, -0.28, 0]} font='/fonts/hanken-grotesk-v8-latin-500.ttf'>System Update</Text>
      <Html
        wrapperClass="fullscreen"
      >

        {[...Array(errors.length)].map((x, i) =>
          <Modal ref={(ref) => (modalsRef.current[i] = ref)} key={"er1_" + i} id={"er1_" + i} modal={errors[i]} cancelF={generateError} continueF={generateError} />
        )}
        <Modal
          id="e1"
          ref={modalRefFinal}
          modal={"error11"}
          continueF={startGame}
        />
  

      </Html>
      <Postprocessing />  
          <CameraControlsBox />
    </>
  );
}
