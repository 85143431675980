import { Suspense } from "react";
import { Preload, AdaptiveDpr, AdaptiveEvents } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
//import { Perf } from "r3f-perf";

import { useStore } from "../components/GameStore.jsx";
import TitleScene from "../scenes/TitleScene.jsx";
import RoomScene from "../scenes/RoomScene.jsx";
import UpdateScene from "../scenes/UpdateScene.jsx";
import TripScene from "../scenes/TripScene.jsx";
import Overlay from "../components/Overlay.jsx";

import LoadingScreen from "../components/LoadingScreen.jsx";





export default function Root(props) {
  document.title = props.title;
  const scene = useStore((state) => state.scene);

  document.body.classList.remove("w__shop");
  document.body.classList.remove("w__webmail");
  document.body.classList.remove("w__about");
  document.body.classList.remove("w__error");
  document.body.classList.add("w__canvas");
  document.getElementById("favicon1").href = "/favicons/favicon.ico";
  document.getElementById("favicon2").href = "/favicons/favicon-16x16.png";
  document.getElementById("favicon3").href = "/favicons/favicon-32x32.png";

  return (
    <>
      <Canvas
        className="r3f"
        camera={{
          fov: 45,
          near: 0.05,
          far: 100,
          position: [0, 0, 7],
        }}
        dpr={[0.5, 1.25]}
      >
             {/* <Perf position="top-left" showGraph={false} />     */}

          <AdaptiveDpr pixelated />
          <AdaptiveEvents />
          <Preload all />
      
          
      
          <Suspense fallback={<LoadingScreen />}>
            {scene === "TitleScene" ? <TitleScene  /> : null}
            {scene === "UpdateScene" ? <UpdateScene  /> : null}
            {scene === "RoomScene" ? <RoomScene  /> : null}
            {scene === "TripScene" ? <TripScene  /> : null}
          
          </Suspense>
     
   
      </Canvas>
      <Overlay close={false} />
    </>
  );
}
