import {

  useGLTF,
  PerspectiveCamera,
 //useTexture,
 useKTX2,
  useAnimations,MeshDistortMaterial
} from "@react-three/drei";
useGLTF.setDecoderPath("../libs/draco/");
//useKTX2.basisPath="../libs/basis/";
import * as THREE from "three";
import { useRef, useEffect } from "react";



export default function PortaleScene(props) {

  const group = useRef();



  const texture = useKTX2("../models/PortalScene.ktx2", "../libs/basis/");
texture.colorSpace = THREE.SRGBColorSpace
  texture.flipY = false;
 texture.channel = 1;

  const material = new THREE.MeshStandardMaterial();
  material.transparent = true;
  material.map = texture;
 material.side = THREE.DoubleSide;
  material.alphaTest = 0.5;


  const { nodes, materials, animations } = useGLTF("../models/PortalScene.glb");
  const { actions, mixer  } = useAnimations(animations, group);

  useEffect(() => {
    

    actions.p1Action.setLoop(THREE.LoopPingPong);
     if (!actions?.p0Action.isRunning()) {
      actions?.p0Action.reset().play();
    }
      if (!actions?.p1Action.isRunning()) {
        actions?.p1Action.reset().play();
      }
     
      if (!actions?.p2Action.isRunning()) {
        actions?.p2Action.reset().play();
      }


           
      if (!actions?.p3Action.isRunning()) {
        actions?.p3Action.reset().play();
      }


           
      if (!actions?.p4Action.isRunning()) {
        actions?.p4Action.reset().play();
      }


           
      if (!actions?.p5Action.isRunning()) {
        actions?.p5Action.reset().play();
      }
           
      if (!actions?.p6Action.isRunning()) {
        actions?.p6Action.reset().play();
      }
           
      if (!actions?.p7Action.isRunning()) {
        actions?.p7Action.reset().play();
      }
           
      if (!actions?.p8Action.isRunning()) {
        actions?.p8Action.reset().play();
      }
           
      if (!actions?.p9Action.isRunning()) {
        actions?.p9Action.reset().play();
      }
  
  }, [mixer]);



  return (
    <>
<ambientLight intensity={2} />
            <PerspectiveCamera makeDefault manual aspect={6.03 / 3.78} position={[0, 0, 5]} /> 
  {/*<ScreenSpace
  depth={1} // Distance from camera
> */}

      <group ref={group} {...props} dispose={null}>
        <group name="Scene">

        <mesh
          name="bg"
     
          geometry={nodes.bg.geometry}
       
          position={[
            nodes.bg.position.x,
            nodes.bg.position.y,
            nodes.bg.position.z,
          ]}>
          <MeshDistortMaterial
          map={texture}
          distort={0.3} speed={1} 
        />
      </mesh>

      <mesh
          name="p0"
 
          geometry={nodes.p0.geometry}
          material={  material}
             position={[
            nodes.p0.position.x,
            nodes.p0.position.y,
            nodes.p0.position.z,
          ]}
        />
        <mesh
          name="p1"
 
          geometry={nodes.p1.geometry}
          material={  material}
             position={[
            nodes.p1.position.x,
            nodes.p1.position.y,
            nodes.p1.position.z,
          ]}
        />
        <mesh
          name="p2"

          geometry={nodes.p2.geometry}
          material={  material}
          position={[
            nodes.p2.position.x,
            nodes.p2.position.y,
            nodes.p2.position.z,
          ]}
        />
        <mesh
          name="p3"

          geometry={nodes.p3.geometry}
          material={  material}
          position={[
            nodes.p3.position.x,
            nodes.p3.position.y,
            nodes.p3.position.z,
          ]}
        />
        <mesh
          name="p4"
   
          geometry={nodes.p4.geometry}
          material={  material}
          position={[
            nodes.p4.position.x,
            nodes.p4.position.y,
            nodes.p4.position.z,
          ]}
        />
        <mesh
          name="p5"
    
          geometry={nodes.p5.geometry}
          material={  material}
          position={[
            nodes.p5.position.x,
            nodes.p5.position.y,
            nodes.p5.position.z,
          ]}
        />
        <mesh
          name="p6"
     
          geometry={nodes.p6.geometry}
          material={  material}
          position={[
            nodes.p6.position.x,
            nodes.p6.position.y,
            nodes.p6.position.z,
          ]}
        />
        <mesh
          name="p7"
     
          geometry={nodes.p7.geometry}
          material={  material}
          position={[
            nodes.p7.position.x,
            nodes.p7.position.y,
            nodes.p7.position.z,
          ]}
        />
        <mesh
          name="p8"
   
          geometry={nodes.p8.geometry}
          material={  material}
          position={[
            nodes.p8.position.x,
            nodes.p8.position.y,
            nodes.p8.position.z,
          ]}
        />
        <mesh
          name="p9"
 
          geometry={nodes.p9.geometry}
          material={  material}
          position={[
            nodes.p9.position.x,
            nodes.p9.position.y,
            nodes.p9.position.z,
          ]}
        />
         
        </group>
      </group>
     {/*  </ScreenSpace>  */}
    </>
  );
}
