

import { Shop, Product, Cart, Checkout } from "../components/shop/pages";
import { Provider } from "react-redux";
import store from "../components/shop/redux/store";
import Overlay from "../components/Overlay.jsx";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";




import { loadCart } from "../components/shop/redux/action";
export default function Ecommerce(props) {
  document.title = props.title;
  document.body.classList.remove("w__canvas");
  document.body.classList.remove("w__about");
  document.body.classList.remove("w__error");
  document.body.classList.remove("w__webmail");
  document.body.classList.add("w__shop");


  document.getElementById("favicon1").href = "/favicons/shop/favicon.ico";
  document.getElementById("favicon2").href = "/favicons/shop/favicon-16x16.png";
  document.getElementById("favicon3").href = "/favicons/shop/favicon-32x32.png";
 

  store.dispatch(loadCart());
 

  const { pathname } = useLocation();

  useLayoutEffect(() => {
 
    document.querySelector('#root').scrollTo(0, 0);

 
  }, [pathname]);


  
  return (
    <>
      <Provider store={store}>

        {props.router === "Shop" ? <Shop /> : null}
        {props.router === "Product" ? <Product /> : null}
        {props.router === "Cart" ? <Cart /> : null}
        {props.router === "Checkout" ? <Checkout /> : null}
      </Provider>

      <Overlay close={true} />
    </>
  );
}
