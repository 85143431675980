import React from "react";
import { useStore } from "../../../components/GameStore.jsx";

export const InboxHtml = ({ parent, messages }) => {
  return (
    <main className="flex-fill">
      <div className="row bg-dark px-md-2">
        <div className="col-12 d-flex flex-column">
          <div className="row">
            <div className="col-lg-3 col-md-4 py-3">

       

              <ul className="list-group sticky-top sticky-offset  bg-light p-1">



              <img className="mx-auto mt-1 mb-3 d-inline webmail__logo" src="/images/webmail/webmailx.png" width={"300px"} height={"auto"}/>


                <button
                  className="btn btn-block btn-secondary my-1"
                  onClick={parent.doCompose}
                >
                  Compose <i className="align-middle icon-pencil" />
                </button>
                <div className="nav nav-pills py-2 flex-md-column justify-content-center">
                  <a
                  href=""
                    className="nav-link active"
                    title="Messages"
                    data-bs-toggle="tab"
                    data-bs-target="#messages"
                    aria-selected="true"
                  >
                    <span className="icon icon-envelope fa fa-fw fa-envelope me-md-1" />
                    <span className="d-none d-md-inline">Messages</span>
                    <span
                      className="badge rounded-pill small bg-dark text-light ms-1"
                      title="Unread"
                    >
                      { messages.filter((v, k) => {
                          return !v.read && !v.deleted;
                        }).length > 0 ? messages.filter((v, k) => {
                          return !v.read && !v.deleted;
                        }).length : null
                      }
                    </span>
                  </a>
                  <a
                 href=""
                    className="nav-link"
                    title="Deleted"
                    data-bs-toggle="tab"
                    data-bs-target="#deleted"
                    aria-selected="false"
                  >
                    <span className="icon icon-trash fa fa-fw fa-trash me-md-1" />
                    <span className="d-none d-md-inline">Deleted</span>
                    <span
                      className="badge rounded-pill small bg-dark text-light ms-1"
                      title="Deleted"
                    >
                       { messages.filter((v, k) => {
                          return !v.read && v.deleted;
                        }).length > 0 ? messages.filter((v, k) => {
                          return !v.read && v.deleted;
                        }).length : null
                      }
                    </span>
                  </a>
                  <a
                   href=""
                    className="nav-link"
                    title="Drafts"
                    data-bs-toggle="tab"
                    data-bs-target="#drafts"
                    aria-selected="false"
                  >
                    <span className="icon icon-pencil fa fa-fw fa-edit me-md-1" />
                    <span className="d-none d-md-inline">Drafts</span>
                  </a>
                </div>
         
              </ul>
        
             
            
            </div>
            <div className="col-md py-3 tab-content">
              <div id="messages" className="tab-pane show active">


                <div className="d-flex flex-sm-row flex-column py-1 mb-1  bg-light p-1">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={parent.toggleMarkAll}
                    >
                      <div
                        className="custom-control custom-checkbox"
                   
                      >
                        {/* <input
                          type="checkbox"
                          className="form-check-input  me-1"
                          id="checkAll"
                          defaultChecked={false}
                          checked={true}
                          onChange={parent.toggleMarkAll}
                        /> */}
                        <label
                          className="custom-control-label"
                          htmlFor="checkAll"
                          onClick={parent.toggleMarkAll}
                        >
                       Mark
                        </label>
                      </div>
                    </button>
                    {  messages &&
                     messages.filter((v, k) => {
                      if (v.marked === 1) {
                        return v;
                      }
                    }).length > 0 ? (
                      <div className="btn-group me-sm-auto me-none">
                        <button
                          type="button"
                          className="btn bg-light text-dark dropdown-toggle"
                          data-bs-toggle="dropdown"
                        />
                        <div className="dropdown-menu bg-secondary text-dark" id="dd1">
                          <a
                            className="dropdown-item bg-secondary text-dark"
                            onClick={parent.deleteMarked}
                          >
                          Delete marked items
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <button
                    type="button"
                    className="btn btn-secondary mx-sm-1 mx-none"
                    onClick={parent.refreshMessages}
                  >
                    <i className="align-middle icon-refresh fas fa-sync" />
                  </button>
              
                </div>
                {/* message list */}
                <ul className="list-group py-2">
                  { messages &&  messages.length > 0
                    ?  messages.map((item, idx) => (

                     ( !item.deleted  ? (
                        <li
                          key={idx}
                          className={"list-group-item list-group-item-action d-block py-1" +
                          (!item.read ? " bg-light" : "")}
                        >
                        <summary className="row">
                        <div className="col-auto pt-1">
                        <div
                              className="custom-control custom-checkbox"
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name={"check" + idx}
                                checked={(item.marked === 1)}
                                onChange={() => parent.toggleMark(idx)}
                              />
                            { (item.marked === 1)}
                            </div>
                          </div>
                        <div className="col" onClick={() => parent.doShow(idx)}>
                        <div className="row">
                          <div className="col py-1 order-1">
                                <div
                                  className={"" + (!item.read ? " font-weight-bold" : "")}
                                >
                                  {item.from}
                                </div>
                          </div>
                          <div className="col-auto px-0 order-2 text-end">
                            {item.attachment ? (
                              <i className="me-1 fa fa-paperclip icon-paper-clip text-dark" />
                            ) : null}
                            <span
                              className={
                                "small d-inline"
                              }
                            >
                              {item.dtSent}
                            </span>
                          </div>
                          <div
                            className="col-12 pb-1 order-3"
                          >
                            <p className={"mb-1 lead" + (!item.read ? " font-weight-bold" : "")}>
                                {item.subject.substring(0, 70)}...
                            </p>
                            <p className="mb-0">
                          {item.body.replace('<br>', ' ').replace('<br>', ' ').substring(0, 80)}...
                      </p>
                          </div>
                          </div>
                          </div>
                        </summary>
                        </li>
                       ) : null )
                      ))
                    : null}
                </ul>
              </div>
              <div id="deleted" className="tab-pane bg-light p-2">
                {/* deleted items */}
                <h5>Deteted E-Mails (  {
                         messages.filter((v, k) => {
                          return v.deleted;
                        }).length
                      })</h5>
                <ul className="list-group py-2">
                { messages &&  messages.length > 0
                    ?  messages.map((item, idx) => (

                     ( item.deleted  ? (
               
         
                            <li
                              key={idx}
                              className={"list-group-item list-group-item-action d-block py-1" +
                              (!item.read ? " bg-light" : "")}
                            >
                            <summary className="row">
                           
                            <div className="col" onClick={() => parent.doShow(idx)}>
                            <div className="row">
                              <div className="col py-1 order-1">
                                    <div
                                      className={"" + (!item.read ? " font-weight-bold" : "")}
                                    >
                                      {item.from}
                                    </div>
                              </div>
                              <div className="col-auto px-0 order-2 text-end">
                                {item.attachment ? (
                                  <i className="me-1 fa fa-paperclip icon-paper-clip text-dark" />
                                ) : null}
                                <span
                                  className={
                                    "small d-inline"
                                  }
                                >
                                  {item.dtSent}
                                </span>
                              </div>
                              <div
                                className="col-12 pb-1 order-3"
                              >
                                <p className={"mb-1 lead" + (!item.read ? " font-weight-bold" : "")}>
                                    {item.subject.substring(0, 70)}...
                                </p>
                                <p className="mb-0">
                              {item.body.replace('<br>', ' ').replace('<br>', ' ').substring(0, 80)}...
                          </p>
                              </div>
                              </div>
                              </div>
                            </summary>
                            </li>
                        )   : null)
                          ))
                        : null}
            
                 
                    </ul>
              </div>
              <div id="drafts" className="tab-pane bg-light p-2">
                <h5>Drafts</h5>
                <p>The folder is empty.</p>
              </div>
           
            </div>
          </div>
          <div className="pb-5 pt-3 text-center mt-auto" >
                <img className="mx-2 mb-2 d-inline mail_footer" src="/images/webmail/ads.png" width={"500px"} height={"auto"}/>
        
              </div>

       

        </div>
      </div>

    </main>
  );
};

export default InboxHtml;
