import {
    EffectComposer,
    Bloom,

    TiltShift2,
    WaterEffect,
    ToneMapping,
 
    Noise,
    Vignette
  } from "@react-three/postprocessing";
  import { BlendFunction } from 'postprocessing'
  import VideoBackground from "../components/VideoBackground.jsx";
  import {  Billboard, Environment } from "@react-three/drei";

export default function Postprocessing04() {

    return (
      <>





     
<EffectComposer  disableNormalPass> 
    {/*    <EffectComposer disableNormalPass>  */}
{/*        <SSAO
    blendFunction={BlendFunction.MULTIPLY} // blend mode
    samples={30} // amount of samples per pixel (shouldn't be a multiple of the ring count)
    rings={4} // amount of rings in the occlusion sampling pattern
    distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
    distanceFalloff={0.0} // distance falloff. min: 0, max: 1
    rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
    rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
    luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
    radius={20} // occlusion sampling radius
    scale={0.5} // scale of the ambient occlusion
    bias={0.5} // occlusion bias
  /> */}
    

     <ToneMapping
    blendFunction={BlendFunction.NORMAL} // blend mode
    adaptive={true} // toggle adaptive luminance map usage
    resolution={256} // texture resolution of the luminance map
    middleGrey={0.5} // middle grey factor
    maxLuminance={15.0} // maximum luminance
    averageLuminance={1.0} // average luminance
    adaptationRate={1.0} // luminance adaptation rate
  />


{/* 
<BrightnessContrast brightness={0.05} contrast={0} />    
     */}
 
      <Bloom mipmapBlur luminanceThreshold={0.8} intensity={2} />     
      {/* <Glitch
    delay={[1.5, 3.5]} // min and max glitch delay
    duration={[0.6, 1.0]} // min and max glitch duration
    strength={[0.1, 0.3]} // min and max glitch strength
    mode={GlitchMode.SPORADIC} // glitch mode
    active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
    ratio={1} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
  />
          <ChromaticAberration
   blendFunction={BlendFunction.NORMAL} // blend mode
   offset={[0.001, 0.001]} // color offset
 />    */}

{/*    
    <Sepia  intensity={0.2} />    */}

         <TiltShift2 samples={8} blur={0.2} />  
         <WaterEffect factor={1.5} />  
    
         <Noise opacity={0.05} />   
         <Vignette eskil={false} offset={0.1} darkness={1.1} /> 
         </EffectComposer>  


         <Environment
       /*      background={true}
            backgroundBlurriness={0.2}
            backgroundIntensity={0.1} */
       
            
            files='kiara_1_dawn_1k3.hdr'
            path="../models/"
          />  
<Billboard>
<VideoBackground
              distance={100}
              intensity={6}
              angle={0.3}
              penumbra={0.2}
              position={[0, -2, 30]}
            />
</Billboard>

        </>
      );
    
}