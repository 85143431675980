import {
    EffectComposer,
    Bloom,

    ToneMapping,  
    Noise,
    Vignette
  } from "@react-three/postprocessing";
  import { BlendFunction } from 'postprocessing'
  import VideoBackground from "../components/VideoBackground.jsx";
  import { Billboard } from "@react-three/drei";
export default function Postprocessing03() {

    return (
      <>


  

         <EffectComposer  disableNormalPass> 

 <Bloom mipmapBlur luminanceThreshold={0.9} intensity={1} />    


         <ToneMapping
    blendFunction={BlendFunction.NORMAL} // blend mode
    adaptive={true} // toggle adaptive luminance map usage
    resolution={256} // texture resolution of the luminance map
    middleGrey={0.8} // middle grey factor
    maxLuminance={20.0} // maximum luminance
    averageLuminance={1} // average luminance
    adaptationRate={1.0} // luminance adaptation rate
  />

    

         


         <Noise opacity={0.05} />  
         <Vignette eskil={false} offset={0.1} darkness={1.1} /> 
         </EffectComposer> 


         <Billboard>
            <VideoBackground
              distance={100}
              intensity={5}
              angle={0.3}
              penumbra={1}
              position={[0, 0, 20]}
            />
          </Billboard> 

        </>
      );
    
}