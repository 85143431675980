import {
    EffectComposer,
    Bloom,

    ToneMapping,  
    Noise,
    Vignette
  } from "@react-three/postprocessing";
  import { BlendFunction } from 'postprocessing'
  import VideoBackground from "../components/VideoBackground.jsx";
  import { Environment } from "@react-three/drei";
export default function Postprocessing01() {

    return (
      <>


{/* <ambientLight intensity={0} /> */}


 
<EffectComposer  disableNormalPass> 




 <ToneMapping
    blendFunction={BlendFunction.NORMAL} // blend mode
    adaptive={true} // toggle adaptive luminance map usage
    resolution={256} // texture resolution of the luminance map
    middleGrey={0.6} // middle grey factor
    maxLuminance={10.0} // maximum luminance
    averageLuminance={1} // average luminance
    adaptationRate={1.0} // luminance adaptation rate
  /> 
   {/* <BrightnessContrast brightness={0.1} contrast={0} />     */}


<Bloom mipmapBlur luminanceThreshold={0.9} intensity={2} />     


         <Noise opacity={0.05} />  
         <Vignette eskil={false} offset={0.1} darkness={1.1} /> 
         </EffectComposer> 


    

          <Environment
        files='kiara_1_dawn_1k2.hdr'
        path="../models/"
            background={false}
        //   backgroundBlurriness={0.2}
         //  backgroundIntensity={0.15}

            // files='kiara_1_dawn_1k.hdr'
            // path="../models/"
          /> 


{/* <Billboard> */}
            <VideoBackground
              distance={100}
              intensity={3}
              angle={0.3}
              penumbra={0.2}
              position={[0, 0, 30]}
            />
          {/* </Billboard>    */}



        </>
      );
    
}