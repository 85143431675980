import {
  Html,
  useGLTF,
  MeshDistortMaterial,
  //useTexture,
  MeshReflectorMaterial,
  useAnimations,Float,MeshTransmissionMaterial,useFBO,useKTX2, Bvh, Detailed
} from "@react-three/drei";
useGLTF.setDecoderPath("../libs/draco/");
//console.log(useKTX2)
//useKTX2.setDecoderPath("../libs/basis/");
import * as THREE from "three";
import { useRef, useEffect } from "react";
import Modal from "../components/Modal.jsx";
import { useStore } from "../components/GameStore.jsx";
import CameraControlsBox from "../components/CameraControlsBox01.jsx";
import Postprocessing from "../components/Postprocessing01.jsx";
import { useFrame } from "@react-three/fiber";


export default function TitleScene(props) {


  const buffer = useFBO()


  const monster = useRef();
  const group = useRef();

  const modalRef = useRef();

  const glass1 = useRef();


 const texture = useKTX2("../models/TitleScene.ktx2", "../libs/basis/");
   //const texture = useTexture("../models/TitleScene.png");
  texture.colorSpace = THREE.SRGBColorSpace
  texture.flipY = false;
 texture.channel = 1;

  const material = new THREE.MeshStandardMaterial();
  material.transparent = true;
  material.map = texture;
 material.side = THREE.DoubleSide;
   material.alphaTest = 0.5;

  const { nodes, materials, animations } = useGLTF("../models/TitleScene.glb");
  const { actions, mixer  } = useAnimations(animations, group);

  useEffect(() => {
    
      actions.fiorearm1Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm2Action.setLoop(THREE.LoopPingPong);


      actions.playAction.setLoop(THREE.LoopPingPong);
      actions.playAction.repetitions = 2;

      actions.legsAction.setLoop(THREE.LoopPingPong);
      actions.legsAction.repetitions = 2;

      actions.dance.setLoop(THREE.LoopOnce);
      actions.dance.clampWhenFinished= true;


      // if (!actions?.dance.isRunning()) {
      //   actions?.dance.reset().play();
      // }


      if (!actions?.fiorearm1Action.isRunning()) {
        actions?.fiorearm1Action.reset().play();
      }
      if (!actions?.fiorearm2Action.isRunning()) {
        actions?.fiorearm2Action.reset().play();
      }



      playDance()


  
  }, [mixer]);



  useFrame((state) => {
    state.gl.autoClear=true

 

    state.gl.setRenderTarget(buffer)


 glass1.current.visible = false;
  

   state.gl.render(state.scene, state.camera)

  

   glass1.current.visible = true
 
      state.gl.setRenderTarget(null)


    
  

  })


const getRandomMillisecond = (min, max) => {
  return Math.random() * (max - min) + min
  }


  const playAnimation = (e) => {
    if (!actions?.playAction.isRunning()) {
      actions?.playAction.reset().play();
    }
    if (!actions?.legsAction.isRunning()) {
      actions?.legsAction.reset().play();
    }
  };
  const playDance = (e) => {
    if (!actions?.dance.isRunning()) {
      actions?.dance.reset()
      .crossFadeFrom(actions?.standing, 0.2, false)
      .play();



      setTimeout(() => {
        playDance()
    }, getRandomMillisecond(7000, 12000));

    

    }
 
  };


  mixer.addEventListener("finished", function (e) {
    if (e.action._clip.name == "dance" ) {
    
      actions?.standing
        .reset()
        .crossFadeFrom(actions?.dance, 0.2, true)
        .play();

   
    }
 
  
  
  });


  const startGame = () => {
    useStore.setState({ scene: "UpdateScene" });
  };

  const openModal = () => {
    modalRef.current.show();
  };

  return (
    <>





         <color args={["#2e2845"]} attach="background" /> 
         <fogExp2 attach="fog" color="#2e2845" density={0.02} />

         {/*   <fog attach="fog" color="#2e2845" near={20} far={50} /> 
 */}


<Bvh firstHitOnly> 
<Detailed distances={[0, 1,3]} >


      <group ref={group} {...props} dispose={null}
        rotation={[0, 0, 0]}
      
      >
        <group name="Scene">

 
        <group
          name="CupContainer"
          position={[
            nodes.CupContainer.position.x,
            nodes.CupContainer.position.y,
            nodes.CupContainer.position.z,
          ]}
          rotation={[
            nodes.CupContainer.rotation.x,
            nodes.CupContainer.rotation.y,
            nodes.CupContainer.rotation.z,
          ]}
          scale={[
            nodes.CupContainer.scale.x,
            nodes.CupContainer.scale.y,
            nodes.CupContainer.scale.z,
          ]}
          >
          <group name="Cup">
            <skinnedMesh
              name="body"
              geometry={nodes.body.geometry}
              material={material}
              skeleton={nodes.body.skeleton}
            />
            <skinnedMesh
              name="eyel"
              geometry={nodes.eyel.geometry}
              material={material}
              skeleton={nodes.eyel.skeleton}
            />
            <skinnedMesh
              name="eyer"
              geometry={nodes.eyer.geometry}
              material={material}
              skeleton={nodes.eyer.skeleton}
            />
            <skinnedMesh
              name="hair"
              geometry={nodes.hair.geometry}
              material={material}
              skeleton={nodes.hair.skeleton}
            />
            <skinnedMesh
              name="limbs"
              geometry={nodes.limbs.geometry}
              material={material}
              skeleton={nodes.limbs.skeleton}
            />
            <skinnedMesh
              name="mouth"
              geometry={nodes.mouth.geometry}
              material={material}
              skeleton={nodes.mouth.skeleton}
            />
            <primitive object={nodes.mixamorigHips} />
          </group>
        </group>
      

 
<Float
  speed={0.5} // Animation speed, defaults to 1
  rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
  floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
  floatingRange={[0,0]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
>
 
          <mesh
            geometry={nodes.monster.geometry}
            ref={monster}
            position={[
              nodes.monster.position.x,
              nodes.monster.position.y,
              nodes.monster.position.z,
            ]}
          >
            <MeshDistortMaterial
              map={texture}
              metalness={0.6}
              roughness={0.6} 

              distort={0.3} speed={1}
            />
          </mesh>
</Float>
          <mesh
            geometry={nodes.boenk.geometry}
            position={[
              nodes.boenk.position.x,
              nodes.boenk.position.y,
              nodes.boenk.position.z,
            ]}
            material={material}
          />

          <group name="legs"
                position={[
                  nodes.legs.position.x,
                  nodes.legs.position.y,
                  nodes.legs.position.z,
                ]}



                rotation={[
                  nodes.legs.rotation.x,
                  nodes.legs.rotation.y,
                  nodes.legs.rotation.z,
                ]   }
    
                scale={[
                  nodes.legs.scale.x,
                  nodes.legs.scale.y,
                  nodes.legs.scale.z,
                ]  }


          
          >
            <skinnedMesh
              name="legsg"
              geometry={nodes.legsg.geometry}
              material={material}
              skeleton={nodes.legsg.skeleton}
        
            />
            <primitive object={nodes.bone03} />
            <primitive object={nodes.ikbone02} />
            <primitive object={nodes.bone01} />
            <primitive object={nodes.ikbone01} />
          </group>
          <group name="playcontainer"
             position={[
              nodes.playcontainer.position.x,
              nodes.playcontainer.position.y,
              nodes.playcontainer.position.z,
            ]   }
            rotation={[
              nodes.playcontainer.rotation.x,
              nodes.playcontainer.rotation.y,
              nodes.playcontainer.rotation.z,
            ]   }

            scale={[
              nodes.playcontainer.scale.x,
              nodes.playcontainer.scale.y,
              nodes.playcontainer.scale.z,
            ]  }
          
          
          
          
        
          >
            <mesh
              name="play"
           
              geometry={nodes.play.geometry}
              material={material}
              onPointerEnter={() => {
                document.body.style.cursor = "url('/images/cursor.png'), pointer";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={openModal}
              onPointerOver={playAnimation}
            />
          </group>

          <group name="fiorearm1">
            <skinnedMesh
              name="fiore1"
              geometry={nodes.fiore1.geometry}
              material={material}
              skeleton={nodes.fiore1.skeleton}
              position={[
                nodes.fiore1.position.x,
                nodes.fiore1.position.y,
                nodes.fiore1.position.z
              ]}
            />
            <primitive object={nodes.Body5} />
          </group>

        <group name="fiorearm2">
            <skinnedMesh
              name="fiore2"
              geometry={nodes.fiore2.geometry}
              material={material}
              skeleton={nodes.fiore2.skeleton}
              position={[
                nodes.fiore2.position.x,
                nodes.fiore2.position.y,
                nodes.fiore2.position.z
              ]}
            />
            <primitive object={nodes.Body5_1} />
          </group> 
       




          <mesh
          name="bottel"
     
          geometry={nodes.bottel.geometry}
          material={material}
          position={[
            nodes.bottel.position.x,
            nodes.bottel.position.y,
            nodes.bottel.position.z,
          ]}
          rotation={[
            nodes.bottel.rotation.x,
            nodes.bottel.rotation.y,
            nodes.bottel.rotation.z,
          ]}
          scale={[
            nodes.bottel.scale.x,
            nodes.bottel.scale.y,
            nodes.bottel.scale.z,
          ]}
        />


          <mesh
          name="white1"
     
          geometry={nodes.white1.geometry}
          material={material}
          position={[
            nodes.white1.position.x,
            nodes.white1.position.y,
            nodes.white1.position.z,
          ]}
          rotation={[
            nodes.white1.rotation.x,
            nodes.white1.rotation.y,
            nodes.white1.rotation.z,
          ]}
          scale={[
            nodes.white1.scale.x,
            nodes.white1.scale.y,
            nodes.white1.scale.z,
          ]}
        />




        
<mesh
          name="white2"
     
          geometry={nodes.white2.geometry}
          material={material}
          position={[
            nodes.white2.position.x,
            nodes.white2.position.y,
            nodes.white2.position.z,
          ]}
          rotation={[
            nodes.white2.rotation.x,
            nodes.white2.rotation.y,
            nodes.white2.rotation.z,
          ]}
          scale={[
            nodes.white2.scale.x,
            nodes.white2.scale.y,
            nodes.white2.scale.z,
          ]}
        />



        <mesh
    
          name="glass"
          ref={glass1}
          geometry={nodes.glass.geometry}
 
          position={[
            nodes.glass.position.x,
            nodes.glass.position.y,
            nodes.glass.position.z,
          ]}
          rotation={[
            nodes.glass.rotation.x,
            nodes.glass.rotation.y,
            nodes.glass.rotation.z,
          ]}
          scale={[
            nodes.glass.scale.x,
            nodes.glass.scale.y,
            nodes.glass.scale.z,
          ]}
      >
         <MeshTransmissionMaterial
              buffer={buffer.texture}

               
                meshPhysicalMaterial={false}
               backside={false}
              color={"#c06ed4"}
              // bg={"#49355b"}
             thickness={0.5}
                // samples={10}
               resolution={1024}
               // roughness={0}
              //ior={1.5}
               // chromaticAberration={0.06}
            //   anisotropy={0.1}
            //      distortion={0}
            //     distortionScale={0.3}
            //      temporalDistortion={0.5}
             clearcoat={0.2}
            //  attenuationDistance={0.1}
                // attenuationColor={"#ffffff"}
                envMapIntensity={0.5}
              />

</mesh>
 
        <mesh
          name="freccia"
      
          geometry={nodes.freccia.geometry}
          material={material}
          position={[
            nodes.freccia.position.x,
            nodes.freccia.position.y,
            nodes.freccia.position.z,
          ]}
          rotation={[
            nodes.freccia.rotation.x,
            nodes.freccia.rotation.y,
            nodes.freccia.rotation.z,
          ]}
          scale={[
            nodes.freccia.scale.x,
            nodes.freccia.scale.y,
            nodes.freccia.scale.z,
          ]}
        />
        <mesh
          name="bilder"
     
          geometry={nodes.bilder.geometry}
          material={material}
          position={[
            nodes.bilder.position.x,
            nodes.bilder.position.y,
            nodes.bilder.position.z,
          ]}
          rotation={[
            nodes.bilder.rotation.x,
            nodes.bilder.rotation.y,
            nodes.bilder.rotation.z,
          ]}
          scale={[
            nodes.bilder.scale.x,
            nodes.bilder.scale.y,
            nodes.bilder.scale.z,
          ]}

        />
        <mesh
          name="skull"
          castShadow
          receiveShadow
          geometry={nodes.skull.geometry}
          material={material}
      


          position={[
            nodes.skull.position.x,
            nodes.skull.position.y,
            nodes.skull.position.z,
          ]}
          rotation={[
            nodes.skull.rotation.x,
            nodes.skull.rotation.y,
            nodes.skull.rotation.z,
          ]}
          scale={[
            nodes.skull.scale.x,
            nodes.skull.scale.y,
            nodes.skull.scale.z,
          ]}

        />



{/*  <mesh
          name="boden"

          geometry={nodes.boden.geometry}
       
          position={[
            nodes.boden.position.x,
            nodes.boden.position.y,
            nodes.boden.position.z,
          ]}
          rotation={[
            nodes.boden.rotation.x,
            nodes.boden.rotation.y,
            nodes.boden.rotation.z,
          ]}
          scale={[
            nodes.boden.scale.x,
            nodes.boden.scale.y,
            nodes.boden.scale.z,
          ]}

        >

<MeshReflectorMaterial
          blur={[300, 300]}
          resolution={1024}
          mixBlur={1}
          mixStrength={1}
          depthScale={2}
          minDepthThreshold={0.4}
          color="#555"
          metalness={0.8}
          roughness={1}
          
        />
  </mesh> */}







        <mesh
          name="back"
          
          geometry={nodes.back.geometry}
          material={material}
          position={[
            nodes.back.position.x,
            nodes.back.position.y,
            nodes.back.position.z,
          ]}
          rotation={[
            nodes.back.rotation.x,
            nodes.back.rotation.y,
            nodes.back.rotation.z,
          ]}
          scale={[
            nodes.back.scale.x,
            nodes.back.scale.y,
            nodes.back.scale.z,
          ]}
        />


<mesh
          name="oggetti2"
      
          geometry={nodes.oggetti2.geometry}
          material={material}
          position={[
            nodes.oggetti2.position.x,
            nodes.oggetti2.position.y,
            nodes.oggetti2.position.z,
          ]}
          rotation={[
            nodes.oggetti2.rotation.x,
            nodes.oggetti2.rotation.y,
            nodes.oggetti2.rotation.z,
          ]}
          scale={[
            nodes.oggetti2.scale.x,
            nodes.oggetti2.scale.y,
            nodes.oggetti2.scale.z,
          ]}
        />






<mesh rotation={[-Math.PI / 2, 0, 0]} 
      
      position={[
        nodes.boden.position.x,
        nodes.boden.position.y,
        nodes.boden.position.z,
      ]}
    
    >
        <circleGeometry args={[8, 50]} />
        <MeshReflectorMaterial
         //  blur={[300, 300]}
          resolution={2048}
         // mixBlur={1}
          mixStrength={1}
          // depthScale={2}
          // minDepthThreshold={0.4}
          color="#b88ac4"
         metalness={0.3}
          roughness={0.9}
          mirror={0.85}
         
        />
      </mesh> 





        </group>
      </group>

      </Detailed >
      </Bvh> 


      <Html wrapperClass="fullscreen">
        <Modal
          id="m1"
          ref={modalRef}
          modal={"Important Update"}
          continueF={startGame}
        />{" "}
      </Html>

      <Postprocessing /> 
      <CameraControlsBox />
    </>
  );
}
