



import {
  Html,
    useGLTF,
    Float,
    MeshReflectorMaterial,
    MeshTransmissionMaterial,
  //useTexture,
useKTX2,
  useAnimations,useFBO, Bvh, Detailed
} from "@react-three/drei";
useGLTF.setDecoderPath("../libs/draco/");
//useKTX2.basisPath="../libs/basis/";
import * as THREE from "three";
import { useRef, useEffect } from "react";
import { useFrame } from "@react-three/fiber";


  import  Modal  from "../components/Modal.jsx";

  import useSound from 'use-sound';
  
  import CameraControlsBox from "../components/CameraControlsBox04.jsx";
  import Postprocessing from "../components/Postprocessing04.jsx";
  import { useStore } from "../components/GameStore.jsx";
  import background from '/sound/psi.mp3';

export default function TripScene(props) {

  const glass1 = useRef();
  const buffer = useFBO()

  useFrame((state) => {
    state.gl.autoClear=true

 
   

    state.gl.setRenderTarget(buffer)
    glass1.current.visible = false;
    state.gl.render(state.scene, state.camera)
    glass1.current.visible = true;
 
    state.gl.setRenderTarget(null)


    
  

  })

  const [playBackground, {stop }] = useSound(background, {
    loop:true, volume:1, interrupt:true
    });

playBackground()
const modalRefFinal = useRef();

const reset = useStore((state) => state.reset);
const resetAll = () => {
  reset();


};
  const group = useRef();
  const camera = useRef();





useEffect(() => {

  return () => {
    stop();
  };
}, [stop]);



useEffect(() => {


 setTimeout(() => {
   modalRefFinal?.current.show()
}, 10000);


   return () => {
  
   };
 }, [ modalRefFinal]);


  const texture = useKTX2("../models/TripScene.ktx2", "../libs/basis/");
texture.colorSpace = THREE.SRGBColorSpace
  texture.flipY = false;
  texture.channel = 1;

  const material = new THREE.MeshStandardMaterial();
  material.transparent = true;
  material.map = texture;
 material.side = THREE.DoubleSide;
  material.alphaTest = 0.5;


  const { nodes, materials, animations } = useGLTF("../models/TripScene.glb");
  const { actions, mixer  } = useAnimations(animations, group);

  useEffect(() => {
    
      actions.fiorearm1Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm2Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm3Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm4Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm5Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm6Action.setLoop(THREE.LoopPingPong);
   
      actions.fly.setLoop(THREE.LoopPingPong);
      if (!actions?.fly.isRunning()) {
        actions?.fly.reset().play();
      }

      if (!actions?.fiorearm1Action.isRunning()) {
        actions?.fiorearm1Action.reset().play();
      }
      if (!actions?.fiorearm2Action.isRunning()) {
        actions?.fiorearm2Action.reset().play();
      }
      if (!actions?.fiorearm3Action.isRunning()) {
        actions?.fiorearm3Action.reset().play();
      }
      if (!actions?.fiorearm4Action.isRunning()) {
        actions?.fiorearm4Action.reset().play();
      }
      if (!actions?.fiorearm5Action.isRunning()) {
        actions?.fiorearm5Action.reset().play();
      }
      if (!actions?.fiorearm6Action.isRunning()) {
        actions?.fiorearm6Action.reset().play();
      }
  
  }, [mixer]);








  return (
    <>



<color args={["#2c2c4b"]} attach="background" /> 
         


         <fog attach="fog" color="#2c2c4b" near={30} far={70} /> 
         <Bvh firstHitOnly> 
         <Detailed distances={[0, 1,3]} >

      <group ref={group} {...props} dispose={null} rotation-x={0.3}>
        <group name="Scene">
        <Float
  speed={0.5}
  rotationIntensity={1} 
  floatIntensity={1} 
 // floatingRange={[0, 0.1]}
>

<group name="CupContainer" position={[ nodes.CupContainer.position.x, nodes.CupContainer.position.y, nodes.CupContainer.position.z]} rotation={[nodes.CupContainer.rotation.x, nodes.CupContainer.rotation.y, nodes.CupContainer.rotation.z]} >
          <group name="Cup">
            <skinnedMesh
              name="body"
              geometry={nodes.body.geometry}
              material={material}
              skeleton={nodes.body.skeleton}
            />
            <skinnedMesh
              name="eyel"
              geometry={nodes.eyel.geometry}
              material={material}
              skeleton={nodes.eyel.skeleton}
            />
            <skinnedMesh
              name="eyer"
              geometry={nodes.eyer.geometry}
              material={material}
              skeleton={nodes.eyer.skeleton}
            />
            <skinnedMesh
              name="hair"
              geometry={nodes.hair.geometry}
              material={material}
              skeleton={nodes.hair.skeleton}
            />
                 <skinnedMesh
              name="limbs"
              geometry={nodes.limbs.geometry}
              material={material}
              skeleton={nodes.limbs.skeleton}
            />
            <skinnedMesh
              name="mouth"
              geometry={nodes.mouth.geometry}
              material={material}
              skeleton={nodes.mouth.skeleton}
            />

<skinnedMesh
              name="v1"
              geometry={nodes.v1.geometry}
              material={material}
              skeleton={nodes.v1.skeleton}
            />
            



            <primitive object={nodes.mixamorigHips} />
          </group>



        </group>
     

        </Float>


          <group name="fiorearm3">
            <skinnedMesh
              name="fiore3"
              geometry={nodes.fiore3.geometry}
              material={material}
              skeleton={nodes.fiore3.skeleton}
              position={[
                nodes.fiore3.position.x,
                nodes.fiore3.position.y,
                nodes.fiore3.position.z
              ]}
            />
            <primitive object={nodes.Body5_1} />
          </group>
          <group name="fiorearm2">
            <skinnedMesh
              name="fiore2"
              geometry={nodes.fiore2.geometry}
              material={material}
              skeleton={nodes.fiore2.skeleton}
              position={[
                nodes.fiore2.position.x,
                nodes.fiore2.position.y,
                nodes.fiore2.position.z
              ]}
            />
            <primitive object={nodes.Body5_2} />
          </group>
          <group name="fiorearm4">
            <skinnedMesh
              name="fiore4"
              geometry={nodes.fiore4.geometry}
              material={material}
              skeleton={nodes.fiore4.skeleton}
              position={[
                nodes.fiore4.position.x,
                nodes.fiore4.position.y,
                nodes.fiore4.position.z
              ]}
            />
            <primitive object={nodes.Body5_3} />
          </group>
          <group name="fiorearm5">
            <skinnedMesh
              name="fiore5"
              geometry={nodes.fiore5.geometry}
              material={material}
              skeleton={nodes.fiore5.skeleton}
              position={[
                nodes.fiore5.position.x,
                nodes.fiore5.position.y,
                nodes.fiore5.position.z
              ]}
            />
            <primitive object={nodes.Body5_4} />
          </group>
          <group name="fiorearm6">
            <skinnedMesh
              name="fiore6"
              geometry={nodes.fiore6.geometry}
              material={material}
              skeleton={nodes.fiore6.skeleton}
              position={[
                nodes.fiore6.position.x,
                nodes.fiore6.position.y,
                nodes.fiore6.position.z
              ]}
            />
            <primitive object={nodes.Body5_5} />
          </group>



          <mesh
          name="pianta1"
    
          geometry={nodes.pianta1.geometry}
          material={material}

          position={[
            nodes.pianta1.position.x,
            nodes.pianta1.position.y,
            nodes.pianta1.position.z,
          ]}
          rotation={[
            nodes.pianta1.rotation.x,
            nodes.pianta1.rotation.y,
            nodes.pianta1.rotation.z,
          ]}
          scale={[
            nodes.pianta1.scale.x,
            nodes.pianta1.scale.y,
            nodes.pianta1.scale.z,
          ]}

        />
        <mesh
          name="pianta2"
 
          geometry={nodes.pianta2.geometry}
          material={material}

          position={[
            nodes.pianta2.position.x,
            nodes.pianta2.position.y,
            nodes.pianta2.position.z,
          ]}
          rotation={[
            nodes.pianta2.rotation.x,
            nodes.pianta2.rotation.y,
            nodes.pianta2.rotation.z,
          ]}
          scale={[
            nodes.pianta2.scale.x,
            nodes.pianta2.scale.y,
            nodes.pianta2.scale.z,
          ]}

        />

     
        <mesh
          name="pianta5"
 
          geometry={nodes.pianta5.geometry}
          material={material}

          position={[
            nodes.pianta5.position.x,
            nodes.pianta5.position.y,
            nodes.pianta5.position.z,
          ]}
          rotation={[
            nodes.pianta5.rotation.x,
            nodes.pianta5.rotation.y,
            nodes.pianta5.rotation.z,
          ]}
          scale={[
            nodes.pianta5.scale.x,
            nodes.pianta5.scale.y,
            nodes.pianta5.scale.z,
          ]}



        />
        <mesh
          name="pianta6"
 
          geometry={nodes.pianta6.geometry}
          material={material}

          position={[
            nodes.pianta6.position.x,
            nodes.pianta6.position.y,
            nodes.pianta6.position.z,
          ]}
          rotation={[
            nodes.pianta6.rotation.x,
            nodes.pianta6.rotation.y,
            nodes.pianta6.rotation.z,
          ]}
          scale={[
            nodes.pianta6.scale.x,
            nodes.pianta6.scale.y,
            nodes.pianta6.scale.z,
          ]}


        />
      

     

     
        <mesh rotation={[-Math.PI / 2, 0, 0]} 
        
        position={[
          nodes.boden.position.x,
          nodes.boden.position.y,
          nodes.boden.position.z,
        ]}


        >
        <circleGeometry args={[100, 50]} />
        <MeshReflectorMaterial
         //  blur={[300, 300]}
          resolution={2048}
         // mixBlur={1}
          mixStrength={2}
          // depthScale={2}
          // minDepthThreshold={0.4}
          color="#b88ac4"
         metalness={0}
          roughness={1}
          mirror={0.95}
         
        />
      </mesh>



      <mesh
      ref={glass1}
          name="glass"
   
          geometry={nodes.glass.geometry}
 
          position={[
            nodes.glass.position.x,
            nodes.glass.position.y,
            nodes.glass.position.z,
          ]}
          rotation={[
            nodes.glass.rotation.x,
            nodes.glass.rotation.y,
            nodes.glass.rotation.z,
          ]}
          scale={[
            nodes.glass.scale.x,
            nodes.glass.scale.y,
            nodes.glass.scale.z,
          ]}
      >
         <MeshTransmissionMaterial
                buffer={buffer.texture}
                meshPhysicalMaterial={false}
               backside={false}
              color={"#c06ed4"}
              // bg={"#49355b"}
             thickness={0.5}
                // samples={10}
               resolution={1024}
               // roughness={0}
              //ior={1.5}
               // chromaticAberration={0.06}
            //   anisotropy={0.1}
            //      distortion={0}
            //     distortionScale={0.3}
            //      temporalDistortion={0.5}
             clearcoat={0.2}
            //  attenuationDistance={0.1}
                // attenuationColor={"#ffffff"}
                envMapIntensity={0.5}
              />

</mesh>
 

        <mesh
          name="back"
      
          geometry={nodes.back.geometry}
          material={material}
          position={[
            nodes.back.position.x,
            nodes.back.position.y,
            nodes.back.position.z,
          ]}
          rotation={[
            nodes.back.rotation.x,
            nodes.back.rotation.y,
            nodes.back.rotation.z,
          ]}
          scale={[
            nodes.back.scale.x,
            nodes.back.scale.y,
            nodes.back.scale.z,
          ]}
        />
        <mesh
          name="bottle001"

          geometry={nodes.bottle001.geometry}
          material={material}

          position={[
            nodes.bottle001.position.x,
            nodes.bottle001.position.y,
            nodes.bottle001.position.z,
          ]}
          rotation={[
            nodes.bottle001.rotation.x,
            nodes.bottle001.rotation.y,
            nodes.bottle001.rotation.z,
          ]}
          scale={[
            nodes.bottle001.scale.x,
            nodes.bottle001.scale.y,
            nodes.bottle001.scale.z,
          ]}

        />

<mesh
          name="p4"

          geometry={nodes.p4.geometry}
          material={material}

          position={[
            nodes.p4.position.x,
            nodes.p4.position.y,
            nodes.p4.position.z,
          ]}
          rotation={[
            nodes.p4.rotation.x,
            nodes.p4.rotation.y,
            nodes.p4.rotation.z,
          ]}
          scale={[
            nodes.p4.scale.x,
            nodes.p4.scale.y,
            nodes.p4.scale.z,
          ]}

        />


        <mesh
          name="bottle3"
         
          geometry={nodes.bottle3.geometry}
          material={material}

          position={[
            nodes.bottle3.position.x,
            nodes.bottle3.position.y,
            nodes.bottle3.position.z,
          ]}
          rotation={[
            nodes.bottle3.rotation.x,
            nodes.bottle3.rotation.y,
            nodes.bottle3.rotation.z,
          ]}
          scale={[
            nodes.bottle3.scale.x,
            nodes.bottle3.scale.y,
            nodes.bottle3.scale.z,
          ]}

        />
        <mesh
          name="bottle1"
       
          geometry={nodes.bottle1.geometry}
          material={material}

          position={[
            nodes.bottle1.position.x,
            nodes.bottle1.position.y,
            nodes.bottle1.position.z,
          ]}
          rotation={[
            nodes.bottle1.rotation.x,
            nodes.bottle1.rotation.y,
            nodes.bottle1.rotation.z,
          ]}
          scale={[
            nodes.bottle1.scale.x,
            nodes.bottle1.scale.y,
            nodes.bottle1.scale.z,
          ]}

        />
      </group>

      </group>
        </Detailed >
      </Bvh> 




      <Html
        wrapperClass="fullscreen"
      >

   
        <Modal
          id="end"
          ref={modalRefFinal}
          modal={"end"}
          continueF={resetAll}
        />
  

      </Html>
         
    
      <Postprocessing />  
          <CameraControlsBox ref={camera}/>


    </>
  );
}

