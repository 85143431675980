import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Link } from "react-router-dom";
import products from "../../../data/products.json";


import Modal from "../../Modal.jsx";
import { useSelector } from "react-redux";


const Products = () => {

  const state = useSelector((state) => state.handleCart);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);

  const modalMessage1 = React.useRef();
  const modalMessage2 = React.useRef();
  const goCart = (e) => {
    window.open("/shop/cart", "_self");
    e.stopPropagation()
  };

  let componentMounted = true;

  const dispatch = useDispatch();

  const addProduct = (product) => {


      // Check if product already in cart
      const exist = state.find((x) => x.id === product.id)
      if(exist){
        modalMessage1.current.show();
      }
      else{
        modalMessage2.current.show();
      }

dispatch(addCart(product))
 


  }

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);

        setData(await  products);
        setFilter(await  products);
        setLoading(false);
     

      return () => {
        componentMounted = false;
      };
    };

    getProducts();
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
    //const updatedList = data.filter((item) => item.category === cat);
    const updatedList = data.filter((item) => (item.category).includes(cat));
   
    setFilter(updatedList);
  }
  const ShowProducts = () => {
    return (
      <>


        <div className="buttons text-center pb-4">
          <button className="btn btn-dark btn-sm m-1" onClick={() => setFilter(data)}>All</button>
          <button className="btn btn-dark btn-sm m-1" onClick={() => filterProduct("for indoor use")}>for indoor use</button>
          <button className="btn btn-dark btn-sm m-1" onClick={() => filterProduct("for outdoor use")}>for outdoor use</button>
    
        </div>

        {filter.map((product) => {
          return (
            <div id={product.id} key={product.id} className="col-md-4 col-sm-6 col-12 mb-4">
              <div className="card text-center h-100" key={product.id}>
              <Link to={"/shop/" + product.id} >
                <img
                  className="card-img-top"
                  src={product.image}
                  alt="Card"
              
                />
</Link>
                <div className="card-body">
                  <h5 className="card-title">
                    {product.title.substring(0, 30)}...
                  </h5>
                  <p className="card-text">
                    {product.description.substring(0, 90)}...
                  </p>
                </div>
                <ul className="list-group list-group-flush m-0 p-0">
                  <li className="list-group-item lead  m-0 py-1">CHF {product.price.toFixed(2)}</li>
                  {/* <li className="list-group-item">Dapibus ac facilisis in</li>
                    <li className="list-group-item">Vestibulum at eros</li> */}
                </ul>
                <div className="card-body">
                  <Link to={"/shop/" + product.id} className="btn btn-dark m-1">
                   View
                  </Link>
                  <button className="btn btn-primary m-1" onClick={() => addProduct(product)}>
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>

          );
        })}
      </>
    );
  };
  return (
    <>
      <div className="container mb-1 pb-3">
        <div className="row">
          <div className="col-12 text-center">
          <div>  <Link to='/shop'><img className="mt-5 mt-lg-3 mb-4 shop__logo" src="/images/shop/Scrub-a-Dub.png" width={"320px"} height={"auto"}/> </Link></div>
      
          </div>
        </div>
        <div className="row">
          {loading ? <Loading /> : <ShowProducts />}
        </div>
      </div>
      <Modal id="m1" ref={modalMessage1} modal={"shop1"} continueF={goCart} />
      <Modal id="m2" ref={modalMessage2} modal={"shop2"} continueF={goCart}/>
    </>
  );
};

export default Products;
